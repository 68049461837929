import { USER_SELECTED_ACCOUNT } from "./Constants";
import { USER_ACCOUNT_ACCESS, USER_INFO, USER_TOKEN_BASE, USER_TOKEN_SECURE } from "./Constants";

export function getStandardAuthHeaders() {
    return {
        headers: {
            tbtc: getBaseToken(),
            tstc: getSecureToken()
          }
    }
}

export function getUser() {
    return JSON.parse(localStorage.getItem(USER_INFO));
}

export function setUser(user) {
    localStorage.setItem(USER_INFO, JSON.stringify(user));
}

export function clearUser() {
    localStorage.removeItem(USER_INFO);
}

export function getBaseToken() {
    return localStorage.getItem(USER_TOKEN_BASE);
}

export function getSecureToken() {
    return localStorage.getItem(USER_TOKEN_SECURE);
}

export function setTokens(baseToken, secureToken) {
    localStorage.setItem(USER_TOKEN_BASE, baseToken);
    localStorage.setItem(USER_TOKEN_SECURE, secureToken);
}

export function clearTokens() {
    localStorage.removeItem(USER_TOKEN_BASE);
    localStorage.removeItem(USER_TOKEN_SECURE);
}

export function getCloudflareJwtCookie() {
    //ImI4ODA4NzAxMDdjZjI2NzU4MmU4NjI3M2U5ZTg5MzBlNjc3MGFlZTI2OTIzODI3YmI4MDU0Yjk1ZjkzNTVkOTciCiAgXSwKICAiZW1haWwiOiAiY2hyaXMyQHRlbml0eC5jb20iLAogICJleHAiOiAxNjg0NzcxNjc5LAogICJpYXQiOiAxNjg0Njg1Mjc5LAogICJuYmYiOiAxNjg0Njg1Mjc5LAogICJpc3MiOiAiaHR0cHM6Ly90ZW5pdHguY2xvdWRmbGFyZWFjY2Vzcy5jb20iLAogICJ0eXBlIjogImFwcCIsCiAgImlkZW50aXR5X25vbmNlIjogIkd3RjZpZTYwcktQN0c4RWEiLAogICJzdWIiOiAiNTJhZjk4MDMtM2I1My00N2ZmLWE2MGMtODg1MmQ0NzBjMTVjIiwKICAiY291bnRyeSI6ICJVUyIKfQ==
    // return "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk0MjlkODAyYWM1ZDI0YWExOWU5MmY5MGJjM2EwNzRlNzczNTc2M2RjMWI0ZmFiODRkNWE4NDcxYzM2NDlkODkifQ.eyJhdWQiOlsiYjg4MDg3MDEwN2NmMjY3NTgyZTg2MjczZTllODkzMGU2NzcwYWVlMjY5MjM4MjdiYjgwNTRiOTVmOTM1NWQ5NyJdLCJlbWFpbCI6ImNocmlzQHRlbml0eC5jb20iLCJleHAiOjE2ODQ3NzE2NzksImlhdCI6MTY4NDY4NTI3OSwibmJmIjoxNjg0Njg1Mjc5LCJpc3MiOiJodHRwczovL3Rlbml0eC5jbG91ZGZsYXJlYWNjZXNzLmNvbSIsInR5cGUiOiJhcHAiLCJpZGVudGl0eV9ub25jZSI6Ikd3RjZpZTYwcktQN0c4RWIiLCJzdWIiOiI1MmFmOTgwMy0zYjUzLTQ3ZmYtYTYwYy04ODUyZDQ3MGMxNWMiLCJjb3VudHJ5IjoiVVMifQ.ZSLnOp-TMFoX6ayhbAVF8xEWkQ0ApGBmY3tE3ZcsnEHvfKh5LAuIaksjcNbYLOZzgpQ-V7aB5QG1OSoSdYjKsb-ukyG3sclpitDUNPxRgrJ6G4BskY1pf9cNch5M-aZLZZBDM0ANM3bSsZB1qVoznoOt9tGqXYtbaSLkP41s0GwUXP-p1jMf9NWvU8zn5duG7mBTayt-DGdI5ZQ228lY7EIFFXJmNCyiS4rOJL39ebHSnLD7USQN9SND2zMVQFlzLTYsvR_URMy0Ahkc_mL6cD-bnwR3nxXvjx6oSkEWcs6YGMcCFfEiuywS-pWZJkYX5LnRLpX5PM4axbfx73pm3A";
    // return "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk0MjlkODAyYWM1ZDI0YWExOWU5MmY5MGJjM2EwNzRlNzczNTc2M2RjMWI0ZmFiODRkNWE4NDcxYzM2NDlkODkifQ.eyJhdWQiOlsiYjg4MDg3MDEwN2NmMjY3NTgyZTg2MjczZTllODkzMGU2NzcwYWVlMjY5MjM4MjdiYjgwNTRiOTVmOTM1NWQ5NyJdLCJlbWFpbCI6ImNocmlzQHRlbml0eC5jb20iLCJleHAiOjE2ODQ3NzE2NzksImlhdCI6MTY4NDY4NTI3OSwibmJmIjoxNjg0Njg1Mjc5LCJpc3MiOiJodHRwczovL3Rlbml0eC5jbG91ZGZsYXJlYWNjZXNzLmNvbSIsInR5cGUiOiJhcHAiLCJpZGVudGl0eV9ub25jZSI6Ikd3RjZpZTYwcktQN0c4RWEiLCJzdWIiOiI1MmFmOTgwMy0zYjUzLTQ3ZmYtYTYwYy04ODUyZDQ3MGMxNWMiLCJjb3VudHJ5IjoiVVMifQ.ZSLnOp-TMFoX6ayhbAVF8xEWkQ0ApGBmY3tE3ZcsnEHvfKh5LAuIaksjcNbYLOZzgpQ-V7aB5QG1OSoSdYjKsb-ukyG3sclpitDUNPxRgrJ6G4BskY1pf9cNch5M-aZLZZBDM0ANM3bSsZB1qVoznoOt9tGqXYtbaSLkP41s0GwUXP-p1jMf9NWvU8zn5duG7mBTayt-DGdI5ZQ228lY7EIFFXJmNCyiS4rOJL39ebHSnLD7USQN9SND2zMVQFlzLTYsvR_URMy0Ahkc_mL6cD-bnwR3nxXvjx6oSkEWcs6YGMcCFfEiuywS-pWZJkYX5LnRLpX5PM4axbfx73pm3A";
    return getCookie("CF_Authorization");
}
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function clearAllUserInfo() {
    clearTokens();
    clearUser();
    clearAccountsUserHasAccessTo();
    clearUsersSelectedAccount();
}

export function setAccountsUserHasAccessTo(businessIds) {
    localStorage.setItem(USER_ACCOUNT_ACCESS, JSON.stringify(businessIds));
}

export function getAccountsUserHasAccessTo() {
    return JSON.parse(localStorage.getItem(USER_ACCOUNT_ACCESS));
}

export function clearAccountsUserHasAccessTo() {
    localStorage.removeItem(USER_ACCOUNT_ACCESS);
}

export function setUsersSelectedAccount(accountId) {
    console.trace("Setting usersSelected account", accountId)
    selectedAccount = accountId;
    localStorage.setItem(USER_SELECTED_ACCOUNT, accountId);
}

export function clearUsersSelectedAccount() {
    localStorage.removeItem(USER_SELECTED_ACCOUNT);
}

export function getSelectedAccount() {
    if (selectedAccount) {
        return selectedAccount;
    }
    selectedAccount = localStorage.getItem(USER_SELECTED_ACCOUNT);
    if (selectedAccount) {
        return selectedAccount;
    }
    selectedAccount = getAccountsUserHasAccessTo()[0].id;
    return selectedAccount;
}

export function isUserLoggedIn() {
    return getUser() && getBaseToken() && getSecureToken();
}

export var selectedAccount;

