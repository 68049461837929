import { CheckCircle, Error, ExpandMore, Pending } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Tooltip } from "@mui/material"
import { DeployStep } from "./deploy/DeployStep"
import { memo } from "react"

export const MemoizedDeploys = memo(ActiveDeploys, (prevProps, nextProps) => {
    return prevProps.isLoading === nextProps.isLoading &&
    prevProps.deploys.length === nextProps.deploys.length && 
    prevProps.deploys.every((deploy, index) => {
        return deploy.deployId === nextProps.deploys[index].deployId
    }) &&
    areNestedDeployArraysEqual(prevProps.deploys, nextProps.deploys)
});

const areNestedDeployArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((deploy, index) => {
        if (deploy.state !== arr2[index].state) {
            return false;
        }
        return deploy.steps.every((step, stepIndex) => {
            return step.completedAt === arr2[index].steps[stepIndex].completedAt
        })
    })
}

export function ActiveDeploys({ deploys, isLoading, isActiveDeploys = true }) {

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!deploys || deploys.length === 0) {
        return <div>No deploys</div>
    }

    /*
    Deploy shape:
    {
        "deployId": "860806b1-f0ab-4305-8ef5-0cf251ba6dba",
        "deployable": {
            "id": "_deploy-test-values-and-features-web-PROD",
            "name": "_deploy-test-values-and-features-web",
            "dockerContainerUrl": "us.gcr.io/tenit-x-tools/values-web:master",
            "environment": "PROD",
            "type": "SERVICE",
            "machineType": "T2_MICRO",
            "storageGb": 8,
            "machineInfo": {
                "cpus": 1,
                "memoryGb": 1.0,
                "networkPerformance": "LOW_TO_MODERATE"
            },
            "appGroup": "_deploy-test-values-and-features-web",
            "cloudBuildTriggerName": "sample-trigger-11",
            "cloudBuildTriggerId": "44db6afb-9fe4-4c41-a652-a94faa3a89b9",
            "betterUptimeBadge": "https://watchdog-badge.tenit.workers.dev/_deploy-test-values-and-features-web.svg",
            "monitoringConfig": {
                "monitorType": "monitor",
                "monitoringStyle": "GROUP_LEVEL",
                "monitorId": "534714"
            },
            "appRoot": "/values-beta"
        },
        "steps": [
            {
                "type": "CREATE_INSTANCES",
                "numInstances": 1,
                "completedAt": 1704639180612
            },
            {
                "type": "VALIDATE_INSTANCES_RUNNING",
                "instanceIds": [
                    "i-048df21e5e86e0287"
                ],
                "completedAt": 1704639219234
            },
            {
                "type": "VALIDATE_INSTANCES_PASSING_HTTP_HEALTH_CHECKS",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "78beb677-e412-4928-96e3-0a3ba6bf9cfb",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-048df21e5e86e0287",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1704639179000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-47-192.ec2.internal",
                        "privateIpAddress": "172.31.47.192",
                        "publicDnsName": "ec2-34-229-243-29.compute-1.amazonaws.com",
                        "publicIpAddress": "34.229.243.29",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "docker-args:-p 80:80",
                            "Name:_deploy-test-values-and-features-web-PROD-1704639178110",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:version:1"
                        ]
                    }
                ],
                "completedAt": 1704639364538
            },
            {
                "type": "CREATE_WATCHDOG_MONITOR",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "78beb677-e412-4928-96e3-0a3ba6bf9cfb",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-048df21e5e86e0287",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1704639179000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-47-192.ec2.internal",
                        "privateIpAddress": "172.31.47.192",
                        "publicDnsName": "ec2-34-229-243-29.compute-1.amazonaws.com",
                        "publicIpAddress": "34.229.243.29",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "docker-args:-p 80:80",
                            "Name:_deploy-test-values-and-features-web-PROD-1704639178110",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:version:1"
                        ]
                    }
                ],
                "completedAt": 1704639364802
            },
            {
                "type": "ADD_TO_LOAD_BALANCER",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "78beb677-e412-4928-96e3-0a3ba6bf9cfb",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-048df21e5e86e0287",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1704639179000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-47-192.ec2.internal",
                        "privateIpAddress": "172.31.47.192",
                        "publicDnsName": "ec2-34-229-243-29.compute-1.amazonaws.com",
                        "publicIpAddress": "34.229.243.29",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "docker-args:-p 80:80",
                            "Name:_deploy-test-values-and-features-web-PROD-1704639178110",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:version:1"
                        ]
                    }
                ],
                "completedAt": 1704639365758
            },
            {
                "type": "VALIDATE_LOAD_BALANCER_EXPECTED_STATE",
                "expectedUpstreams": [
                    {
                        "host": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "id": "i-02ec7e6ca3b3f773e"
                    },
                    {
                        "host": "ec2-34-229-243-29.compute-1.amazonaws.com",
                        "id": "i-048df21e5e86e0287"
                    }
                ],
                "completedAt": 1704639366141
            },
            {
                "type": "REMOVE_FROM_LOAD_BALANCER",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-02ec7e6ca3b3f773e",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-36-20.ec2.internal",
                        "privateIpAddress": "172.31.36.20",
                        "publicDnsName": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "publicIpAddress": "3.81.185.18",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-args:-p 80:80",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    }
                ],
                "completedAt": 1704639366868
            },
            {
                "type": "VALIDATE_LOAD_BALANCER_EXPECTED_STATE",
                "expectedUpstreams": [
                    {
                        "host": "ec2-34-229-243-29.compute-1.amazonaws.com",
                        "id": "i-048df21e5e86e0287"
                    }
                ],
                "completedAt": 1704639367065
            },
            {
                "type": "REMOVE_WATCHDOG_MONITOR",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-02ec7e6ca3b3f773e",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-36-20.ec2.internal",
                        "privateIpAddress": "172.31.36.20",
                        "publicDnsName": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "publicIpAddress": "3.81.185.18",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-args:-p 80:80",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    }
                ],
                "completedAt": 1704639367134
            },
            {
                "type": "KILL_INSTANCES",
                "instanceIds": [
                    "i-02ec7e6ca3b3f773e"
                ],
                "completedAt": 1704639367720
            },
            {
                "type": "VALIDATE_INSTANCES_NOT_RUNNING",
                "instanceIds": [
                    "i-02ec7e6ca3b3f773e"
                ],
                "completedAt": -1
            }
        ],
        "finishedAt": -1,
        "startedAt": 1704639178027
    },
    {
        "deployId": "140a60a9-f9ac-4a2c-9bde-ebcdc86cebbd",
        "deployable": {
            "id": "_deploy-test-values-and-features-web-PROD",
            "name": "_deploy-test-values-and-features-web",
            "dockerContainerUrl": "us.gcr.io/tenit-x-tools/values-web:master",
            "environment": "PROD",
            "type": "SERVICE",
            "machineType": "T2_MICRO",
            "storageGb": 8,
            "machineInfo": {
                "cpus": 1,
                "memoryGb": 1.0,
                "networkPerformance": "LOW_TO_MODERATE"
            },
            "appGroup": "_deploy-test-values-and-features-web",
            "cloudBuildTriggerName": "sample-trigger-11",
            "cloudBuildTriggerId": "44db6afb-9fe4-4c41-a652-a94faa3a89b9",
            "betterUptimeBadge": "https://betteruptime.com/status-badges/v1/monitor/bgl6.svg",
            "monitoringConfig": {
                "monitorType": "monitor",
                "monitoringStyle": "GROUP_LEVEL",
                "monitorId": "534714"
            },
            "appRoot": "/values-beta"
        },
        "steps": [
            {
                "type": "CREATE_INSTANCES",
                "numInstances": 2,
                "completedAt": 1703919152156
            },
            {
                "type": "VALIDATE_INSTANCES_RUNNING",
                "instanceIds": [
                    "i-02ec7e6ca3b3f773e",
                    "i-002be6f85434e0566"
                ],
                "completedAt": 1703920159089
            },
            {
                "type": "VALIDATE_INSTANCES_PASSING_HTTP_HEALTH_CHECKS",
                "instances": [
                    {
                        "amiLaunchIndex": 1,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-002be6f85434e0566",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-41-205.ec2.internal",
                        "privateIpAddress": "172.31.41.205",
                        "publicDnsName": "ec2-174-129-87-184.compute-1.amazonaws.com",
                        "publicIpAddress": "174.129.87.184",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    },
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-02ec7e6ca3b3f773e",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-36-20.ec2.internal",
                        "privateIpAddress": "172.31.36.20",
                        "publicDnsName": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "publicIpAddress": "3.81.185.18",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-args:-p 80:80",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    }
                ],
                "completedAt": 1703920159111
            },
            {
                "type": "CREATE_WATCHDOG_MONITOR",
                "instances": [
                    {
                        "amiLaunchIndex": 1,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-002be6f85434e0566",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-41-205.ec2.internal",
                        "privateIpAddress": "172.31.41.205",
                        "publicDnsName": "ec2-174-129-87-184.compute-1.amazonaws.com",
                        "publicIpAddress": "174.129.87.184",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    },
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-02ec7e6ca3b3f773e",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-36-20.ec2.internal",
                        "privateIpAddress": "172.31.36.20",
                        "publicDnsName": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "publicIpAddress": "3.81.185.18",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-args:-p 80:80",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    }
                ],
                "completedAt": 1703920159420
            },
            {
                "type": "ADD_TO_LOAD_BALANCER",
                "instances": [
                    {
                        "amiLaunchIndex": 1,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-002be6f85434e0566",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-41-205.ec2.internal",
                        "privateIpAddress": "172.31.41.205",
                        "publicDnsName": "ec2-174-129-87-184.compute-1.amazonaws.com",
                        "publicIpAddress": "174.129.87.184",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    },
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "db6382f2-7481-411b-9723-e06ab1ae3537",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-02ec7e6ca3b3f773e",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703919150000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-36-20.ec2.internal",
                        "privateIpAddress": "172.31.36.20",
                        "publicDnsName": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "publicIpAddress": "3.81.185.18",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "pending",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703919149568",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-args:-p 80:80",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD"
                        ]
                    }
                ],
                "completedAt": 1703920160160
            },
            {
                "type": "VALIDATE_LOAD_BALANCER_EXPECTED_STATE",
                "expectedUpstreams": [
                    {
                        "host": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "id": "i-02ec7e6ca3b3f773e"
                    },
                    {
                        "host": "ec2-174-129-87-184.compute-1.amazonaws.com",
                        "id": "i-002be6f85434e0566"
                    },
                    {
                        "host": "ec2-54-156-33-8.compute-1.amazonaws.com",
                        "id": "i-0fc86827e2cda2bce"
                    },
                    {
                        "host": "ec2-35-153-132-3.compute-1.amazonaws.com",
                        "id": "i-0cad36f10d8b1e192"
                    }
                ],
                "completedAt": 1703920160364
            },
            {
                "type": "REMOVE_FROM_LOAD_BALANCER",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "70f675b6-8cfe-4049-84b3-a4933483fd56",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-0fc86827e2cda2bce",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703905519000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-44-83.ec2.internal",
                        "privateIpAddress": "172.31.44.83",
                        "publicDnsName": "ec2-54-156-33-8.compute-1.amazonaws.com",
                        "publicIpAddress": "54.156.33.8",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:version:1",
                            "Name:_deploy-test-values-and-features-web-PROD-1703905518781",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master"
                        ]
                    },
                    {
                        "amiLaunchIndex": 1,
                        "architecture": "x86_64",
                        "clientToken": "70f675b6-8cfe-4049-84b3-a4933483fd56",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-0cad36f10d8b1e192",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703905519000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-47-198.ec2.internal",
                        "privateIpAddress": "172.31.47.198",
                        "publicDnsName": "ec2-35-153-132-3.compute-1.amazonaws.com",
                        "publicIpAddress": "35.153.132.3",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703905518781",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master"
                        ]
                    }
                ],
                "completedAt": 1703920160811
            },
            {
                "type": "VALIDATE_LOAD_BALANCER_EXPECTED_STATE",
                "expectedUpstreams": [
                    {
                        "host": "ec2-3-81-185-18.compute-1.amazonaws.com",
                        "id": "i-02ec7e6ca3b3f773e"
                    },
                    {
                        "host": "ec2-174-129-87-184.compute-1.amazonaws.com",
                        "id": "i-002be6f85434e0566"
                    }
                ],
                "completedAt": 1703920161082
            },
            {
                "type": "REMOVE_WATCHDOG_MONITOR",
                "instances": [
                    {
                        "amiLaunchIndex": 0,
                        "architecture": "x86_64",
                        "clientToken": "70f675b6-8cfe-4049-84b3-a4933483fd56",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-0fc86827e2cda2bce",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703905519000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-44-83.ec2.internal",
                        "privateIpAddress": "172.31.44.83",
                        "publicDnsName": "ec2-54-156-33-8.compute-1.amazonaws.com",
                        "publicIpAddress": "54.156.33.8",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:version:1",
                            "Name:_deploy-test-values-and-features-web-PROD-1703905518781",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master"
                        ]
                    },
                    {
                        "amiLaunchIndex": 1,
                        "architecture": "x86_64",
                        "clientToken": "70f675b6-8cfe-4049-84b3-a4933483fd56",
                        "ebsOptimized": false,
                        "imageId": "ami-02dea0e200ddb3c74",
                        "instanceId": "i-0cad36f10d8b1e192",
                        "instanceType": "t2.micro",
                        "keyName": "standard-key-pair",
                        "launchTime": 1703905519000,
                        "platformDetails": "Linux/UNIX",
                        "privateDnsName": "ip-172-31-47-198.ec2.internal",
                        "privateIpAddress": "172.31.47.198",
                        "publicDnsName": "ec2-35-153-132-3.compute-1.amazonaws.com",
                        "publicIpAddress": "35.153.132.3",
                        "rootDeviceName": "/dev/xvda",
                        "rootDeviceType": "ebs",
                        "state": "running",
                        "tags": [
                            "Name:_deploy-test-values-and-features-web-PROD-1703905518781",
                            "deployable:_deploy-test-values-and-features-web-PROD",
                            "docker-args:-p 80:80",
                            "aws:ec2launchtemplate:version:1",
                            "tenit-runtime-env:PROD",
                            "aws:ec2launchtemplate:id:lt-03285daefad7eff3e",
                            "docker-image:us.gcr.io/tenit-x-tools/values-web:master"
                        ]
                    }
                ],
                "completedAt": 1703920161446
            },
            {
                "type": "KILL_INSTANCES",
                "instanceIds": [
                    "i-0cad36f10d8b1e192",
                    "i-0fc86827e2cda2bce"
                ],
                "completedAt": 1703920161932
            },
            {
                "type": "VALIDATE_INSTANCES_NOT_RUNNING",
                "instanceIds": [
                    "i-0cad36f10d8b1e192",
                    "i-0fc86827e2cda2bce"
                ],
                "completedAt": -1
            }
        ],
        "finishedAt": -1,
        "startedAt": 1703919149277
    },
    */

    const getLatestNonTerminatedStep = (steps) => {
        const nonTerminatedSteps = steps.filter(step => step.completedAt === -1)
        if (nonTerminatedSteps.length === 0) {
            return null
        }
        return nonTerminatedSteps[0];
    }

    const getTimeOfLastCompletedStep = (steps) => {
        const completedSteps = steps.filter(step => step.completedAt !== -1);
        console.log("completedSteps", completedSteps)
        const lastCompletedStep = completedSteps[completedSteps.length - 1];
        console.log("lastCompletedStep", lastCompletedStep)
        if (!lastCompletedStep) {
            return null
        }
        return lastCompletedStep.completedAt
    }

    const timestampToReadableTimeFromNow = (timestamp) => {
        const timeSince = Date.now() - timestamp
        return timeDeltaToReadableTime(timeSince);
    }

    const timeDeltaToReadableTime = (timeSince) => {
        const seconds = Math.floor(timeSince / 1000)
        const minutes = Math.floor(seconds / 60)
        const hours = Math.floor(minutes / 60)
        const days = Math.floor(hours / 24)
        if (days > 0) {
            return `${days} days`
        }
        if (hours > 0) {
            return `${hours} hours`
        }
        if (minutes > 0) {
            return `${minutes} minutes`
        }
        return `${seconds} seconds`
    }

    const DeployRow = ({ deploy }) => {
        return (
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <div 
                    style={
                        {
                            // display: 'flex',
                            justifyContent: 'space-between',
                            textAlign: 'left',
                            alignItems: 'left',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            margin: '10px 0'
                        }
                    }
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DeployStateToIcon state={deploy.state} />
                            <h2>{deploy.deployId}</h2>
                        </div>
                        <h5>{isActiveDeploys && getLatestNonTerminatedStep(deploy.steps).type}</h5>
                        <h5>Last update: {timestampToReadableTimeFromNow(getTimeOfLastCompletedStep(deploy.steps))} ago</h5>
                        <h5>Started: {timestampToReadableTimeFromNow(deploy.startedAt)} ago</h5>
                        {!isActiveDeploys && <h5>Duration: {timeDeltaToReadableTime(deploy.finishedAt - deploy.startedAt)}</h5>}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {deploy.steps.map((step, index, steps) => <DeployStep 
                    previousStepFinishedAt={index > 0 ? steps[index-1].completedAt : deploy.startedAt} 
                    step={step} 
                    hasFailed={deploy.state === 'FAILED'}
                    finishedAt={deploy.finishedAt}
                    />)}
                </AccordionDetails>

            </Accordion>
        )
    }


    return (
        <div>
            <Accordion defaultExpanded={isActiveDeploys}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h1>{isActiveDeploys ? 'Active' : 'Completed'} Deploys</h1>
            </AccordionSummary>
            <AccordionDetails>
                {deploys.map(deploy => (<DeployRow deploy={deploy} />))}
            </AccordionDetails>
            </Accordion>

            {/* <table>
                <thead>
                    <tr>
                        <th>Deploy ID</th>
                        {isActiveDeploys && <th>Current Step</th>}
                        {isActiveDeploys && <th>Time on Step</th>}
                        <th>Deployed At</th>
                        {!isActiveDeploys && <th>Deploy length</th>}
                    </tr>
                </thead>
                <tbody>
                    {deploys.map(deploy => (
                        <tr key={deploy.deployId}>
                            <td>{deploy.deployId}</td>
                            {isActiveDeploys && <td>{getLatestNonTerminatedStep(deploy.steps).type}</td>}
                            {isActiveDeploys && <td>{timestampToReadableTimeFromNow(getTimeOfLastCompletedStep(deploy.steps))} ago</td>}
                            <td>{timestampToReadableTimeFromNow(deploy.startedAt)} ago</td>
                            {
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </div>
    )
}

/*
Possible States:
 PENDING,
  RUNNING,
  SUCCEEDED,
  FAILED
*/
function DeployStateToIcon({ state }) {
    if (state === 'PENDING') {
        return <Tooltip title="Pending"><Pending sx={{color: "grey"}}/></Tooltip>
    }
    if (state === 'RUNNING') {
        return <Tooltip title="Running"><CircularProgress /></Tooltip>

    }
    if (state === 'SUCCEEDED') {
        return <Tooltip title="Succceeded"><CheckCircle sx={{color: "green"}}/></Tooltip>
        
    }
    if (state === 'FAILED') {
        return <Tooltip title="Failed"><Error sx={{color: "red"}}/></Tooltip>
        
    }
    return <span>Unknown</span>
}