import { Lan, Storage } from "@mui/icons-material";
import CloudIcon from "@mui/icons-material/Cloud"; // service
import LayersIcon from "@mui/icons-material/Layers"; // worker
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"; //  mobile
import WebIcon from "@mui/icons-material/Web"; // web ui
import { TENIT_SECONDARY_COLOR } from "../Colors";

export const ServiceNameWithLogo = (params) => {
  const { name, type } = params;

  const TypeIcon = () => {
    if (type === "SERVICE") {
      return (
        <CloudIcon
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    } else if (type === "WORKER") {
      return (
        <LayersIcon
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    } else if (type === "MOBILE_REACT_NATIVE") {
      return (
        <PhoneIphoneIcon
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    } else if (type === "REACT_WEB_UI") {
      return (
        <WebIcon
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    }
    else if (type === "LOAD_BALANCER") {
      return (
        <Lan
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    }
    else if (type === "MEMCACHED") {
      return (
        <Storage
          style={{ color: TENIT_SECONDARY_COLOR, marginTop: "2.5%", paddingRight: "5%" }}
        />
      );
    }
    return <></>;
  };

  return (
    <div style={{ display: "flex" }}>
      <TypeIcon />
      <div>{name}</div>
    </div>
  );
};
