import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import ReactJson from "react-json-view";
import JSONInput from "react-json-editor-ajrm";
import { Card, Chip } from "react-rainbow-components";
import { getBaseToken, getCloudflareJwtCookie, getSecureToken } from "../api/User";

const hosts = [
  {
    label: "(PROD) https://api.tenitx.com",
    value: "https://api.tenitx.com",
  },
  {
    label: "(Localhost) http://local.tenitx.com:8080",
    value: "http://local.tenitx.com:8080",
  },
];

export default function ApiCallerPage() {
  const docPaths = [
    "CoreServices",
    "log-fetch-web",
    "notics-tracer",
    "session-replay-web",
    "watchdog-web",
  ];

  const [urls, setUrls] = useState([]);
  const [definitions, setDefinitions] = useState({});

  const [host, setHost] = useState({
    label: "(PROD) https://api.tenitx.com",
    value: "https://api.tenitx.com",
  });

  const [path, setPath] = useState("");
  const [method, setMethod] = useState("GET");

  // Vars
  const [pathParams, setPathParams] = useState({});
  const [requestBody, setRequestBody] = useState({});

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});
  const [statusCode, setStatusCode] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    if (!path) {
      return;
    }
    const params = {};
    [...path.matchAll("{.*?}")].forEach((arr) => {
      const v = arr[0];
      console.log("New Path Var: ", v);
      params[v] = pathParams[v] || "";
    });
    setPathParams(params);
  }, [path]);

  useEffect(() => {
    const fetchSwaggerDocsNonOptimized = async () => {
      let arr = [];
      let defs = {};
      docPaths.forEach(async (path) => {
        const swagger = (
          await axios.get(`https://swagger-docs.tenitx.com/${path}/swagger.json`)
        ).data;
        const base = swagger.basePath.substring(0, swagger.basePath.length - 1);
        if (!swagger.paths) {
          return;
        }
        Object.keys(swagger.paths).forEach((a) =>
          Object.keys(swagger.paths[a]).forEach((method) => {
            const endpoint = swagger.paths[a][method];
            arr.push({
              path: base + a,
              method: method.toUpperCase(),
              parameters: endpoint.parameters || [],
            });
          })
        );
        defs = { ...swagger.definitions, ...defs };
        setDefinitions(defs);
      });
      setUrls(arr);
      setDefinitions(defs);
    };

    fetchSwaggerDocsNonOptimized();
  }, []);

  const sendRequest = () => {
    setLoading(true);
    setResponse({});
    setStatusCode();
    setTime();
    let url = `${host.value}${path}`;
    console.log("PathParams", pathParams);
    Object.keys(pathParams).forEach((param) => {
      url = url.replace(param, pathParams[param]);
    });
    console.log(url);

    const config = {
      headers: {
        tbtc: getBaseToken(),
        tstc: getSecureToken(),
        cfJwt: getCloudflareJwtCookie()
      },
      method: method,
      url: url,
      data: requestBody || null,
    };

    const startTime = performance.now();
    axios
      .request(config)
      .then((r) => handleResponse(r, startTime))
      .catch((e) => handleError(e))
      .finally(handleFinally);
  };

  const handleResponse = (resp, startTime) => {
    const time = performance.now() - startTime;
    setResponse(resp.data);
    setStatusCode(resp.statusCode);
    setTime(time.toFixed(1));
    console.log("Resp size: ", resp.data.length);
  };

  const handleError = (e) => {
    console.error(e);
    console.log(e);
    setStatusCode(e.response.data.code);
    setResponse({ errorMessage: e.response.data.message });
    // setResponse(e)
  };

  const handleFinally = () => {
    setLoading(false);
  };

  const PathParamInputs = () => {
    return (
      <div>
        {Object.keys(pathParams).length > 0 && <h2>Path Parameters</h2>}
        {Object.keys(pathParams).map((param) => {
          return (
            <PathParamInput
              param={param}
              defaultValue={pathParams[param]}
              onChange={(v) => {
                let p = pathParams;
                p[param] = v;
                setPathParams(p);
                console.log(p);
              }}
            />
          );
        })}
      </div>
    );
  };
  // use material ui chip and color based on method
  const HttpMethodChip = ({ method }) => {
    const colors = {
      GET: "green",
      POST: "blue",
      PUT: "orange",
      PATCH: "purple",
      DELETE: "red",
    };
    return (
      <Chip
        style={{ color: "white", backgroundColor: colors[method] }}
        label={method}
      />
    );
  };

  const definitionToJsonObjectExample = (definition) => {
    console.log("definition", definition);
    if (!definition) {
      return {};
    }
    const obj = {};
    Object.keys(definition.properties).forEach((prop) => {
      const type = definition.properties[prop].type;
      if (type === "string") {
        obj[prop] = "string";
      } else if (type === "integer") {
        obj[prop] = 0;
      } else if (type === "array") {
        obj[prop] = [];
      } else if (type === "boolean") {
        obj[prop] = false;
      } else if (type === "object") {
        obj[prop] = definitionToJsonObjectExample(definition.properties[prop]);
      }
    });
    return obj;
  };

  const unwrapParameterDefinitionToObject = (parameter) => {
    if (!parameter) {
      return {};
    }
    const definition =
      definitions[
        parameter.schema.$ref.substring(
          parameter.schema.$ref.lastIndexOf("/") + 1
        )
      ];
    console.log("definition", definition);
    return definitionToJsonObjectExample(definition);
  };

  const stringifyQueryParams = (params) => {
    let str = "";
    params.forEach((param) => {
      str += `${param}={${param}}&`;
    });
    // remove last &
    return str.substring(0, str.length - 1);
  };

  return (
    <div style={{ marginTop: -2 }}>
      <h1>API Caller</h1>
      <PathParamInputs />
      <Grid
        container
        columns={10}
        rowSpacing={{ xs: 2, sm: 2, md: 3 }}
        columnSpacing={{ xs: 2, sm: 2, md: 3 }}
        style={{ width: "95%", margin: "auto" }}
      >
        <Grid item xs={1.5}>
          <Autocomplete
            disablePortal
            disableClearable
            id="top-level-coordinate-input"
            options={["GET", "POST", "PATCH", "PUT", "DELETE"]}
            value={method}
            renderInput={(params) => <TextField {...params} label="Method" />}
            onChange={(_, newValue) => {
              setMethod(newValue);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            disableClearable
            id="top-level-coordinate-input"
            options={hosts}
            value={host}
            renderInput={(params) => <TextField {...params} label="Host" />}
            onChange={(_, newValue) => {
              setHost(newValue);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            freeSolo={true}
            disableClearable
            id="top-level-coordinate-input2"
            options={urls}
            value={path}
            filterOptions={(options, params) => {
              const filtered = options.filter((o) =>
                o.path.toLowerCase().includes(params.inputValue.toLowerCase())
              );
              return filtered;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Api Path (watchdog/v1)"
                onChange={(v) => setPath(v.target.value)}
              />
            )}
            renderOption={(props, option) => {
              return (
                <div {...props}>
                  <HttpMethodChip method={option.method} />
                  <h3>{option.path}</h3>
                </div>
              );
            }}
            onChange={(_, newValue) => {
              if (typeof newValue === "string") {
                console.log("v1:", newValue);
                setPath(newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                console.log("v2:", newValue.inputValue);
                setPath(newValue.inputValue);
              } else {
                console.log("v3:", newValue);
                let query = stringifyQueryParams(
                  newValue.parameters
                    .filter((p) => p.in === "query")
                    .map((p) => p.name)
                );
                if (query) {
                  query = "?" + query;
                }
                setPath(newValue.path + query);
                setMethod(newValue.method);
                setRequestBody(
                  unwrapParameterDefinitionToObject(
                    newValue.parameters.find((p) => p.in === "body")
                  ) || {}
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={sendRequest}
          >
            Send
          </LoadingButton>
        </Grid>
      </Grid>
      {["POST", "PATCH", "PUT"].includes(method) && (
        <JSONInput
          id="a_unique_id"
          placeholder={requestBody}
          // colors      = { darktheme }
          // locale      = { locale }
          style={{
            body: { textAlign: "left" },
            outerBox: {
              marginLeft: 40,
              marginTop: 10,
              textAlign: "left",
              borderRadius: 5,
              padding: 10,
            },
          }}
          height="100"
          onChange={(e) => setRequestBody(e.jsObject)}
        />
      )}
      <h2>{statusCode}</h2>
      <h4>{time}ms</h4>
      <h4>{formatBytes(byteSize(JSON.stringify(response)))}</h4>
      <ReactJson
        style={{ marginLeft: 50, marginTop: 50, textAlign: "left" }}
        name={false}
        displayDataTypes={false}
        collapsed={2}
        src={response}
      />
    </div>
  );
}

function PathParamInput({ defaultValue, param, onChange }) {
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <div key={param} style={{ display: "flex" }}>
      <h2>{param}</h2>
      <TextField
        label={param}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const byteSize = (str) => new Blob([str]).size;
