import { CircularProgress, Tooltip } from "@mui/material";
import { useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useInterval } from "./Utils";
import { QuestionMark } from "@mui/icons-material";

export const LoadBalancerInclusionStatus = ({ value }) => {
  console.log("LoadBalancerInclusionStatus", value)
  const [lastCheck, setLastCheck] = useState(new Date());
  const [d, setD] = useState(0);

  useInterval(() => {
    setD(Math.floor((new Date() - lastCheck) / 1000));
  }, 2500);

  const Status = ({status}) => {
    // console.log("status", status)
    switch (status) {
      case "loading":
        return <CircularProgress style={{ color: "#6A54F3" }} />;
      case "included":
        return <CheckCircleIcon style={{ color: "green" }} />;
      case "not_found":
        return <CloseRoundedIcon style={{ color: "red" }} />;
      default: 
        return <QuestionMark style={{ color: "grey" }}/>;
    }
  };

  return (
    <div>
      <div style={{ marginLeft: "25%", marginTop: "5%" }}>
        <div style={{display: "flex"}}>
          {value.map((e) => <Tooltip title={e.originIp} placement="top-start" style={{margin: 10}}><Status status={e.status} /></Tooltip>)}
        </div>
      </div>
      <div style={{ marginLeft: "10%" }}>{`Last Checked ${d}s ago`}</div>
    </div>
  );
};
