import { useState, useEffect } from "react";
import { Picklist, Option } from "react-rainbow-components";

export default function BuildTriggersSelector ({
    buildTriggers,
    preselectedTrigger,
    onSelect,
  }) {/*
  {
    "id": "61b47644-5cd8-41fc-a79f-4812c8a84e25",
    "createTime": "2021-11-15T09:02:41.233630460Z",
    "github": {
      "push": {
        "branch": "^master$"
      },
      "owner": "EarnzProduct",
      "name": "CoreServices"
    },
    "autodetect": true,
    "name": "CoreServicesTrigger",
    "resourceName": "projects/earnz-product/locations/global/triggers/61b47644-5cd8-41fc-a79f-4812c8a84e25"
  },
  */
  const [selectedTrigger, setSelectedTrigger] = useState(preselectedTrigger);

  useEffect(() => {
    if (!selectedTrigger || selectedTrigger === preselectedTrigger) {
      return;
    }
    onSelect(buildTriggers.find((t) => t.id === selectedTrigger.name));
  }, [selectedTrigger]);

  return (
    <Picklist
      id="build-triggers-picklist"
      //style={containerStyles}
      onChange={setSelectedTrigger}
      value={selectedTrigger}
      label="Select Build Trigger"
    >
      {buildTriggers.map((m) => (
        <Option
          name={m.id}
          label={`${m.github?.owner}/${m.github?.name} (${m.name})`}
        />
      ))}
    </Picklist>
  );
};