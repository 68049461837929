import { createRef, useEffect, useRef, useState } from "react";
import TableWithBrowserPagination from "react-rainbow-components/components/TableWithBrowserPagination";
import Column from "react-rainbow-components/components/Column";
import RadioButtonGroup from "react-rainbow-components/components/RadioButtonGroup";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import Modal from "react-rainbow-components/components/Modal";
import {
  bounce,
  deploy,
  getBuildsForTrigger,
  getDeployable,
  getInstance,
  getListOfInstancesForDeployable,
  isFrontEnd,
  isMobileApp,
  killInstance,
  replaceInstance,
  startBuildForTrigger,
} from "../api/OverwatchApi";
import { apiCaller } from "../api/API";
import { useNavigate, useParams } from "react-router";
import { AwsInstanceStatus } from "../components/AwsInstanceStatus";
import { StandardHttpHealthCheckStatus } from "../components/StandardHttpHealthCheckStatus";
import { useInterval } from "../components/Utils";
import { LoadBalancerInclusionStatus } from "../components/LoadBalancerInclusionStatus";
import { DeployableInputModal } from "./DeployableInputModal";
import { GCBBuildStatus } from "../components/GCBBuildStatus";
import { Replay, Warning } from "@mui/icons-material";
import { Chip, useIsFocusVisible } from "@mui/material";
import { useQueryClient } from "react-query";
import { Spinner } from "react-rainbow-components";

export default function ManageInstance() {

  const [instance, setInstance] = useState();
  const [command, setCommand] = useState();


  const history = useNavigate();

  const { deployableId, instanceId } = useParams();

  useEffect(() => {
    getInstance(deployableId, instanceId, (e) => {
      setInstance(e);
      // setIsFetchingInstances(false);
    });
  }, [deployableId, instanceId]);

  const [openCommandOutputModal, setOpenCommandOutputModal] = useState(false);

  return (
    <div>
      <h1>Manage Instance</h1>
      <h2>{instanceId}</h2>
      
      <div 
      style={{ display: "flex",  justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Button
          id="button-1"
          variant="brand"
          label="Back"
          onClick={() => history(`/overwatch/${deployableId}`)}
          style={{ marginLeft: 10 }}
        />
       {instance && instance.publicDnsName &&
        <Button
            id="button-1"
            variant="brand"
            label="Command"
            onClick={() => setOpenCommandOutputModal(true)}
        />}
        <Button
          id="button-1"
          variant="brand"
          label="Replace Instance"
          onClick={() => {
            replaceInstance(instanceId, () => {history(`/overwatch/${deployableId}`)})
          }}
        />
        <Button
          id="button-1"
          variant="brand"
          label="Kill Instance"
          onClick={() => {
            killInstance(instanceId, () => {history(`/overwatch/${deployableId}`)})
          }}
        />
        {instance && instance.publicDnsName && 
        <CommandOutputModal 
          host={instance.publicDnsName} 
          command={command} 
          isOpen={openCommandOutputModal} 
          onClose={() => setOpenCommandOutputModal(false)} 
        />}
      </div>
    </div>
  );
}


// build a react compontent accepts a command and a callback
// when the command is run, it will call the callback with the output
// the output will be displayed in a modal
function CommandOutputModal({ host, isOpen, onClose }) {
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [command, setCommand] = useState();

  const usefulCommands = [
    "docker ps",
    "systemctl status apirunner.service",
    "systemctl restart apirunner.service",
  ];

  const [commandHistory, setCommandHistory] = useState([]);

  const runCommand = (command) => {
    setLoading(true);
    setCommandHistory([...commandHistory, command]);
    const commandSplit = command.split(" ");
    const commandBase = commandSplit[0];
    const args = commandSplit.slice(1);
    apiCaller
      .post(
        `/overwatch/v2/agent/command`, 
      {
        command: commandBase,
        args: args,
      },
      {
        params: {
          host: host
        }
      })
      .then((d) => {
        console.log(d.data)
        setOutput([...output, d.data]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error", err)
        if (err.response.data.code) {
          //error
          setOutput([...output, `${err.response.data.code}: ${err.response.data.message}`]);
          setLoading(false);
          return;
        }
        setOutput([...output, err.response.data]);
        setLoading(false);
      });
  }

  return (
    <Modal
      id="modal-1"
      isOpen={isOpen}
      onRequestClose={() => {
        // setOutput([]);
        onClose();
      }}
      style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 20, boxSizing: "border-box" }}
    >
      <h3>Useful Commands</h3>
      {usefulCommands.map(c => <CommandHistoryElement command={c} onClick={() => runCommand(c)} />)}
      <h2>History</h2>
      {commandHistory.map(c => <CommandHistoryElement command={c} onClick={() => runCommand(c)} />)}
      <h1>{command}</h1>
      <p>Output:</p>
      {output.map(o => {
        return <pre
        // add style to wrap text
        style={{ whiteSpace: "pre-wrap",  marginBottom: 10  }}
        >
          {o}
          </pre>;
      })}
      <Spinner size="large" variant="brand" isVisible={loading} />
      <Input
        label="Command"
        value={command}
        disabled={loading}
        onKeyDown={(e) => {
          console.log("key press", e.key)
          if (e.key === "Enter") {
            runCommand(command);
            setCommand("");
          }
        }}
        onChange={(e) => setCommand(e.target.value)}
      />
      <Button
        id="button-1"
        variant="brand"
        label="Run Command"
        onClick={() => {
          runCommand(command);
          setCommand("");
        }}
        style={{ marginLeft: "auto", marginRight: 10 }}
      />
      
    </Modal>
  );
}

function CommandHistoryElement({ command, onClick }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{command}</div>
      <Button
        id="button-1"
        variant="base"
        label="Run Command"
        onClick={onClick}
        style={{ marginLeft: 10 }} 
      >
        <Replay />
      </Button>
    </div>
  )
}

function CommandInputModal({ isOpen, onClose, onSubmit }) {
  const [command, setCommand] = useState();

  return (
    <Modal
      id="modal-1"
      isOpen={isOpen}
      onRequestClose={() => {
        setCommand("");
        onClose();
      }}
    >
      <Input
        label="Command"
        value={command}
        onChange={(e) => setCommand(e.target.value)}
      />
      <Button
        id="button-1"
        variant="brand"
        label="Run Command"
        onClick={() => {
          onSubmit(command);
          setCommand("");
          onClose();
        }}
        style={{ marginLeft: "auto", marginRight: 10 }}
      />
    </Modal>
  );
}