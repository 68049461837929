import { useEffect, useState } from "react";
import { getLoadBalancerMap } from "../api/OverwatchApi";
import TreeView from '@mui/lab/TreeView';
import { TreeItem } from "@mui/lab";
import { ArrowDownward, ChevronRight } from "@mui/icons-material";
import { StandardHttpHealthCheckStatus } from "../components/StandardHttpHealthCheckStatus";

export default function LoadBalancers() {
  const [routes, setRoutes] = useState([]);
 

  const getLoadBalancerData = async() => {
    getLoadBalancerMap(setRoutes);
  }

  useEffect(() => getLoadBalancerData, []);

  const LoadBalancerTree = () => {
    return (
      <>
        {Object.keys(routes).map(route => 
          <>
            <TreeItem style={{padding: 10}} nodeId="2" label={`${route} (${routes[route].length})`}> 
            {routes[route].map(children => 
              <TreeItem style={{padding: 5}} nodeId="3" label={children.host} icon={<StandardHttpHealthCheckStatus value={(children.host || "").replace("http://", "")} hideLastTimeChecked={true} onlyCheckStatusOnce={true} />} />
            )}
            </TreeItem>
          </>
          )}
      </>
    )
  };

  
  return (
    <div style={{textAlign: "left"}}>
      <TreeView
           aria-label="customized"
           defaultExpanded={['1', '2', '3']}
           defaultCollapseIcon={<ArrowDownward />}
           defaultExpandIcon={<ChevronRight />}
          //  defaultEndIcon={<CloseSquare />}
           sx={{ height: 600, flexGrow: 1 }}
      >
        {
          <LoadBalancerTree />
        }
      </TreeView>
    </div>
  )
}