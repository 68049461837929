import { Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";

export const GCBBuildStatus = (params) => {
  const { value, isFrontEnd } = params;

  /*
   const STATUS_LOOKUP = [
    'UNKNOWN',
    'Queued',
    'Working',
    'Success',
    'Failure',
    'Error',
    'Timeout',
    'Cancelled',
  ];
   */
  const Status = () => {
    switch (value.toLowerCase()) {
      case "success":
        return (
          <>
            <CheckCircleIcon style={{ color: "green" }} /> Success
          </>
        );
      case "queued":
        return (
          <>
            <WarningIcon style={{ color: "yellow" }} /> Queued
          </>
        );
      case "working":
        return (
          <>
            <CircularProgress style={{ color: "blue" }} />{" "}
            {isFrontEnd ? "Deploying" : "Building"}...
          </>
        );
      case "cancelled":
        return (
          <>
            <WarningIcon style={{ color: "gold" }} /> Cancelled
          </>
        );
      case "failure":
        return (
          <>
            <WarningIcon style={{ color: "red" }} /> Failed
          </>
        );

      case "shutting-down":
      case "stopping":
        return (
          <Chip
            variant="outlined"
            style={{ borderColor: "red", color: "red", width: "50%" }}
            label="Shutting Down"
          />
        );
      case "stopped":
        return (
          <Chip
            variant="outlined"
            style={{ backgroundColor: "red", color: "white", width: "50%" }}
            label="Stopped"
          />
        );
      default:
        return <>{value}</>;
    }
  };

  return <Status />;
};
