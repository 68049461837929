import { useEffect, useState } from "react";
import Button from "react-rainbow-components/components/Button";
import { Card } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { getAllMonitors } from "../api/WatchdogApi";
import { WatchdogStatus } from "../components/WatchdogStatus";

export default function WatchdogOverview() {

  const { bucket, prefixPath } = useParams();

  const [monitors, setMonitors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  const loadData = () => {
    setIsLoading(true);
    getAllMonitors().then(setMonitors).finally(() => setIsLoading(false));
  }

  useEffect(loadData, []);



  const MonitorCard = ({monitor}) => {

    const convertSecondsToText = (seconds) => {
      if (seconds < 60) {
        return `${Math.floor(seconds)} seconds`;
      } else if (seconds < 60 * 60) {
        return `${Math.floor(seconds / 60)} minutes`;
      } else if (seconds < 60 * 60 * 24) {
        return `${Math.floor(seconds / 60 / 60)} hours`;
      } else if (seconds < 60 * 60 * 24 * 7) {
        return `${Math.floor(seconds / 60 / 60 / 24)} days`;
      } else {
        return `${Math.floor(seconds / 60 / 60 / 24 / 7)} weeks`;
      }
    }

    const timeSinceLastChecked = (new Date().getTime() - new Date(monitor.lastChecked).getTime()) / 1000;
    const timeSinceLastStateChange = (new Date().getTime() - new Date(monitor.timeOfLastStateChange).getTime()) / 1000;



    return (
      <Card sx={{ width: "100%", margin: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1rem" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
            <div style={{ alignItems: "center"}}>
              <h3>{monitor.monitorGroup}</h3>
              <h5>{monitor.name}</h5>
              <WatchdogStatus status={monitor.state} />
              <span style={{ marginLeft: "1rem" }}>for {convertSecondsToText(timeSinceLastStateChange)}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "1rem" }}>Last checked: {convertSecondsToText(timeSinceLastChecked)} ago</span>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="neutral"
              label="View"
              onClick={() => history(`/watchdog/${monitor.name}`)}
            />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <div style={{textAlign: "left"}} >
      <div style={{display: "flex"}}>
        <h1 style={{marginLeft: "2%"}}>Watchdog</h1>
        <Button style={{marginLeft: "auto", marginRight: "2.5%"}} variant="base" isLoading={isLoading} onClick={() => loadData()}>Refresh</Button>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "1rem", width: "95%" }}>
        {monitors.map((monitor) => <MonitorCard monitor={monitor} />)}
      </div>
    </div>
  );
}
