import { CheckCircle, Error, HourglassEmpty } from "@mui/icons-material";
import { Table } from "@mui/material";

/*
 "steps": [
            {
                "type": "CREATE_INSTANCES",
                "numInstances": 1,
                "completedAt": 1704676715072
            },
            {
                "type": "VALIDATE_INSTANCES_RUNNING",
                "instanceIds": [
                    "i-08dbc70feafcd6f48"
                ],
                "completedAt": 1704676741155
            },
*/
export const DeployStep = ({ finishedAt = -1, previousStepFinishedAt, step, hasFailed = false }) => {
    return (
        <>
        <div
        style={
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                margin: '10px 0'
            }
        }
        >
        <StateIcon completedAt={step.completedAt} hasFailed={hasFailed} previousStepFinishedAt={previousStepFinishedAt} />
        <h3>{humanizeStepType(step.type)}</h3>
        {step.completedAt > -1 ? 
        <h4>{timeDeltaToReadableTime(step.completedAt - previousStepFinishedAt)}</h4>
        : 
        previousStepFinishedAt > -1 ?
        <h4>{timeDeltaToReadableTime((finishedAt > -1 ? finishedAt : Date.now()) - previousStepFinishedAt)}</h4> : <h4>-</h4>}
        </div>
        {step.type === "RUN_CANARY_TESTS" && step.resultsByInstance && <CanaryTestResults canaryTestResults={step.resultsByInstance} />}
        </>
    );
}

// CREATE_INSTANCES -> Create Instances
function humanizeStepType(stepType) {
    return stepType.split('_').map(word => word.toLowerCase()).join(' ');
}

function StateIcon({hasFailed, previousStepFinishedAt, completedAt}) {
    if (hasFailed && completedAt === -1) {
        if (previousStepFinishedAt > -1) {
            return <Error sx={{color: 'red'}}/>
        }
        return '-';
    }

    return (
        <div>
            {completedAt > -1 ? <CheckCircle sx={{color: 'green'}}/> : <HourglassEmpty />}
        </div>
    );
}

const timeDeltaToReadableTime = (timeSince) => {
    const seconds = Math.floor(timeSince / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    if (days > 0) {
        return `${days} days`
    }
    if (hours > 0) {
        return `${hours} hours`
    }
    if (minutes > 0) {
        return `${minutes} minutes`
    }
    return `${seconds} seconds`
}

const CanaryTestResults = ({canaryTestResults}) => {
    return (
        <div>
            <h2>Canary Test Results</h2>
            <div>
                {Object.keys(canaryTestResults).map((instanceId, index) => (
                    <div key={index}>
                        <h3>Instance ID: {instanceId}</h3>
                        <Table>
                                    <thead>
                                        <tr>
                                            <th>Path</th>
                                            <th>Response Time Change</th>
                                            <th>Response Code</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                        {canaryTestResults[instanceId].map((result) => (
                            <tr>
                                                <td align="left">{result.path}</td>
                                                <td>{result.responseTimeDelta} ms</td>
                                                <td>Expected: {result.originalResponseCode} | Test: {result.canaryResponseCode}</td>
                                                <td>{result.statusTheSame ? <CheckCircle sx={{color: "green"}}/> : <Error sx={{color: 'red'}}/>}</td>
                                                </tr>
                                        ))}
                                         
                        </tbody>
                    </Table>
                    </div>
                ))}
            </div>
        </div>
    );
}