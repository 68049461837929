import { apiCaller } from "./API";

export const getListOfDeployables = async () => {
  return apiCaller
    .get(`/overwatch/v2/deployable`)
    .then((d) => d.data)
    .catch((err) => console.error("Error", err));
};

export const getDeployable = (id, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${id}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getListOfInstancesForDeployable = (id, includeLoadBalancerCheck, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${id}/instances?includeLoadBalancerCheck=${includeLoadBalancerCheck}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getInstance = (deployableId, instanceId, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${deployableId}/instances/${instanceId}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
}

export const replaceInstance = (instanceId, callback) => {
  apiCaller
    .put(`/overwatch/v2/deployable/instance/${instanceId}/replace`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
}

export const killInstance = (instanceId, callback) => {
  apiCaller
    .delete(`/overwatch/v2/deployable/instance/${instanceId}/kill`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
}

export const deploy = (id, deployOptions, callback) => {
  apiCaller
    .post(`/overwatch/v2/deployable/${id}/deploy`, deployOptions)
    .then((d) => callback())
    .catch((err) => console.error("Error", err));
};

export const asyncDeploy = (id, strategy, deployOptions, callback) => {
  apiCaller
    .post(`/overwatch/v2/deployable/${id}/deploy-async?strategy=${strategy}`, deployOptions)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getDeploy = (deployableId, deployId, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${deployableId}/deploy-async/${deployId}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getActiveDeploys = (deployableId, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${deployableId}/deploy-async/active`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};


export const getCompletedDeploys = (deployableId, callback) => {
  apiCaller
    .get(`/overwatch/v2/deployable/${deployableId}/deploy-async/completed`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const upsertDeployable = (deployable, callback) => {
  apiCaller
    .post(`/overwatch/v2/deployable`, deployable)
    .then((d) => callback())
    .catch((err) => console.error("Error", err));
};

export const getListOfEc2MachineTypes = (callback) => {
  apiCaller
    .get(`/overwatch/v2/meta/ec2-machine-types`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const isExternalIpAssignedToLoadBalancer = (lbIp, ip, callback) => {
  apiCaller
    .get(`/overwatch/v2/meta/load-balancer-check-proxy?url=http://${lbIp}:81/Mesh`)
    .then((d) => {
      let doesContainIp = false;
      let obj = d.data;
      Object.keys(obj).forEach((k) => {
        if (obj[k].includes(`http://${ip}`)) {
          doesContainIp = true;
        }
      });
      callback(doesContainIp);
    })
    .catch((err) => callback(false));
};

export const getLoadBalancerInfo = (lbIp, callback) => {
  apiCaller
    .get(`/overwatch/v2/meta/load-balancer-check-proxy?url=http://${lbIp}:81/Mesh`
    )
    .then((d) => {
      callback(d.data);
    })
    .catch((err) => console.error("Error", err));
};

export const getLoadBalancerInfoAsync = async (lbIp) => {
  return apiCaller
    .get(`/overwatch/v2/meta/load-balancer-check-proxy?url=http://${lbIp}:81/Mesh`
    )
    .then((d) => d.data);
};

export const getLoadBalancerMap = (callback) => {
  apiCaller
    .get(`/overwatch/v2/load-balancer`)
    .then((d) => {
      callback(d.data);
    })
    .catch((err) => console.error("Error", err));
};

export const getBuildsForTrigger = (triggerId, callback) => {
  apiCaller
    .post(
      `/overwatch/v2/deployable/builds`,
      { filter: triggerId }
    )
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const startBuildForTrigger = (triggerName, branch, callback) => {
  apiCaller
    .post(
      `/overwatch/v2/deployable/buildToDeploy`,
      {
        trigger: triggerName,
        branch: branch,
      }
    )
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const bounce = (id, callback) => {
  apiCaller
    .post(`/overwatch/v2/deployable/${id}/bounce`)
    .then((d) => callback())
    .catch((err) => console.error("Error", err));
};

export const listBuildTriggers = (callback) => {
  apiCaller
    .get(`/overwatch/v2/builds/build-triggers`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const createMonitor = (monitorRequest, callback) => {
  apiCaller
    .post(`/overwatch/v2/better-uptime/monitors`, monitorRequest)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const createMonitorGroup = (monitorRequest, callback) => {
  apiCaller
    .post(
      `/overwatch/v2/better-uptime/monitor-groups`,
      monitorRequest
    )
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getUptimePassthrough = (uri, callback) => {
  apiCaller
    .get(`/overwatch/v2/better-uptime/pass-through?path=${uri}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const postUptimePassthrough = (uri, body, callback) => {
  apiCaller
    .post(
      `/overwatch/v2/better-uptime/pass-through?path=${uri}`,
      body
    )
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};


export function isFrontEnd(type) {
  let v =
    type && (type === "MOBILE_REACT_NATIVE" || type === "REACT_WEB_UI");
  return v;
}

export function isApiService(type) {
  return type && type === "SERVICE";
}

export function isMobileApp(type) {
  let v = type && type === "MOBILE_REACT_NATIVE";
  return v;
}
