import { useEffect, useState } from "react";
import TableWithBrowserPagination from "react-rainbow-components/components/TableWithBrowserPagination";
import Column from "react-rainbow-components/components/Column";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import { Chip } from "@mui/material";
import { ServiceNameWithLogo } from "../components/ServiceNameWithLogo";
import { getListOfDeployables } from "../api/OverwatchApi";
import { useNavigate } from "react-router";
import { DeployableInputModal } from "./DeployableInputModal";
import { TENIT_PRIMARY_COLOR, TENIT_SECONDARY_COLOR } from "../Colors";
import { useQuery, useQueryClient } from "react-query";
import { DEPLOYABLES_LIST } from "../api/ReactQueryKeys";

export default function Overwatch() {
  // const [deployables, setDeployables] = useState([]);
  const [search, setSearch] = useState();

  const [addDeployableModalOpen, setAddDeployableModelOpen] = useState(false);

  // const [isFetchingDeployables, setIsFetchingDeployables] = useState(true);

  const history = useNavigate();

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery(DEPLOYABLES_LIST, async () => {
    console.log("useQuery fetch function")
    return (await getListOfDeployables())
  }
  );

  // useEffect(
  //   () =>
  //     getListOfDeployables((e) => {
  //       setDeployables(e);
  //       setIsFetchingDeployables(false);
  //     })
  //     ,
  //   []
  // );

  return (
    <div>
      <Button
        id="button-1"
        variant="neutral"
        label="Add Deployable"
        onClick={() => setAddDeployableModelOpen(true)}
      />
      <DeployableInputModal
        isOpen={addDeployableModalOpen}
        onClose={() => setAddDeployableModelOpen(false)}
      />
      <Input
        label="Deployable Search"
        placeholder="Search for Deployable"
        type="search"
        className="rainbow-p-around_medium"
        style={{ width: 300 }}
        value={search}
        onChange={(v) => setSearch(v.target.value)}
      />
      <TableWithBrowserPagination
        isLoading={isLoading}
        style={{ width: "100%" }}
        pageSize={20}
        data={
          search
            ? data.filter((d) => d.name.includes(search))
            : data
        }
        keyField="id"
      >
        <Column
          header="Name"
          field="name"
          component={(e) => {
            return <ServiceNameWithLogo name={e.row.name} type={e.row.type} />;
          }}
        />
        <Column
          header="Environment"
          field="environment"
          component={(e) => (
            <Chip
              style={{
                backgroundColor: TENIT_SECONDARY_COLOR,//`${e.value === "QA" ? "#aca1ed" : "#6A54F3"}`,
                color: "white",
                width: "50%",
              }}
              label={e.value}
            />
          )}
        />
        <Column
          field="betterUptimeBadge"
          cellAlignment="center"
          component={(e) => (
              e.value && e.value.startsWith("https://watchdog-badge") ? (
                <img src={`${e.value}?t=${Date.now()}`} height={50} alt=""/>
              ) : (
                <img src={e.value} width={100} alt=""/>
              )
          )}
        />
        <Column
          header=""
          field="dockerUrl"
          component={(e) => {
            return (
              <Button
                id="button-2"
                variant="neutral"
                label="Manage"
                onClick={() => history(`/overwatch/${e.row.id}`)}
              />
            );
          }}
        />
      </TableWithBrowserPagination>
    </div>
  );
}
