// status: PENDING, HEALTHY, VALIDATING_OUTAGE, DOWN, VALIDATING_RECOVERY
export function WatchdogStatus ({status}) {
    const statusToColor = {
        "PENDING": "orange",
        "HEALTHY": "green",
        "VALIDATING_OUTAGE": "orange",
        "DOWN": "red",
        "VALIDATING_RECOVERY": "orange"
    };
    const statusToText = {
        "PENDING": "Pending",
        "HEALTHY": "Healthy",
        "VALIDATING_OUTAGE": "Validating outage",
        "DOWN": "Down",
        "VALIDATING_RECOVERY": "Validating recovery"
    };
    return (
        <span style={{color: statusToColor[status]}}>{statusToText[status]}</span>
    );
}