import { useEffect, useState } from "react";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, Chip, Link, List, ListItemAvatar, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { DeployableInputModal } from "./DeployableInputModal";
import { TENIT_PRIMARY_COLOR, TENIT_SECONDARY_COLOR } from "../Colors";
import TreeView from '@mui/lab/TreeView';
import { ArrowDownward, ArrowUpward, Article, ChevronRight, ExpandMore, Folder, Remove, Storage } from "@mui/icons-material";
import { queryLogs } from "../api/LogFetchApi";
import { Option, Picklist, DateTimePicker, Select } from "react-rainbow-components";
import { LogsChart } from "../components/logging/LogsChart";

let queryCache = {
  deployablesByLogType: {
    // AccessLogs: []
  },
  fieldsByLogType: {
    // AccessLogs: []
  }
};

const comparisons = [
  {
    label: '=',
    name: '=',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["String", "Int16"]
  },
  {
    label: '!=',
    name: '!=',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["String", "Int16"]
  },
  {
    label: '>',
    name: '>',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["Int16", "DateTime64(3)"]
  },
  {
    label: '<',
    name: '<',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["Int16", "DateTime64(3)"]
  },
  {
    label: '>=',
    name: '>=',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["Int16", "DateTime64(3)"]
  },
  {
    label: '<=',
    name: '<=',
    expectsValue: true,
    isFunction: false,
    allowedTypes: ["Int16", "DateTime64(3)"]
  },
  {
    label: 'Like',
    name: 'like',
    expectsValue: true,
    isFunction: true,
    allowedTypes: ["String"]
  },
  {
    label: 'Starts With',
    name: 'startsWith',
    expectsValue: true,
    isFunction: true,
    allowedTypes: ["String"]
  },
  {
    label: 'Ends With',
    name: 'endsWith',
    expectsValue: true,
    isFunction: true,
    allowedTypes: ["String"]
  },
  {
    label: 'Is Null',
    name: 'isNull',
    expectsValue: false,
    isFunction: true,
    allowedTypes: ["String", "Int16"]
  },
  {
    label: 'Is Not Null',
    name: 'isNotNull',
    expectsValue: false,
    isFunction: true,
    allowedTypes: ["String", "Int16"]
  },
];

export default function LogFetch() {
  const nav = useNavigate();


  const logTypes = [
    {
      label: "Service Logs",
      table: "ApplicationLogs"
    },
    {
      label: "Access Logs",
      table: "AccessLogs"
    }
];

const [logType, setLogType] = useState(logTypes[0]);
const [deployable, setDeployable] = useState();
const [deployables, setDeployables] = useState([]);

const [fields, setFields] = useState([]);

const [filters, setFilters] = useState([]);
const [groupBy, setGroupBy] = useState([]);
const [sort, setSort] = useState({
  field: "logTimestamp",
  direction: "DESC"
});
const [limit, setLimit] = useState(100);

const [queryBuilder, setQueryBuilder] = useState(`SELECT * FROM ${logType.table}`);

const [isLoading, setIsLoading] = useState(false);
const [logs, setLogs] = useState([]);
const [error, setError] = useState();

  useEffect(
    () => {
      if (queryCache.deployablesByLogType[logType.table]) {
        setDeployables(queryCache.deployablesByLogType[logType.table])
      } else {
        queryLogs({
          query: `SELECT appGroup FROM ${logType.table} GROUP BY appGroup`
        }, (d) => {
          setDeployables(d.map(a => a.appGroup))
          queryCache.deployablesByLogType[logType.table] = d.map(a => a.appGroup);
        })
      }
      
      if (queryCache.fieldsByLogType[logType.table]) {
        setFields(queryCache.fieldsByLogType[logType.table])
      } else {
        queryLogs({
          query: `DESCRIBE ${logType.table}`
        }, (d) => {
          setFields(d);
          queryCache.fieldsByLogType[logType.table] = d;
        })
      }
    }, [logType]
  );

  useEffect(() => {

    if (deployable) {
      setFilters([...filters.filter(f => f.field !== 'appGroup'),
        {
          field: 'appGroup',
          filterName: '=',
          value: `${deployable.name}`
        }
      ])
    } else {
      setFilters(filters.filter(f => f.field !== 'appGroup'))
    }
  }, [deployable]);
  

  useEffect(
    () => {
      buildQuery();
    }, [logType, filters, groupBy, limit, sort]
  );

  const runQuery = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    setIsLoading(true);
    setLogs([]);
    queryLogs({
      query: queryBuilder
    },
    (results) => {
      setIsLoading(false)
      console.log("", results.length === 1, results[0].error)
      if (results.length === 1 && results[0].error) {
        setError(results[0].error)
        setLogs([]);
        return;
      }
      setError();
      setLogs(results);
    })
  }

  const buildQuery = () => {
    setQueryBuilder(
      `SELECT ${groupByFieldSelect()} FROM ${logType.table} ${filters.length > 0 ? 'WHERE ' : ''} ${filters.map(f => filterToQueryString({filter: f})).join(" AND ")} ${groupByFieldsGroupBy()} ${buildSortParams()} LIMIT ${limit}`
    )
  }

  const buildSortParams = () => {
    if (groupBy.length === 0) {
      return `ORDER BY ${sort.field} ${sort.direction}`;
    }

    let maybeMatch = groupBy.find(f => f.name === sort.field);
    if (!maybeMatch && sort.field === 'count') {
      maybeMatch = {name: 'count'};
    }
    if (maybeMatch) {
      return `ORDER BY ${maybeMatch.alias || maybeMatch.name} ${sort.direction}`;
    }

    return '';
  }

  const groupByFieldSelect = () => {
    if (groupBy.length === 0) {
      return '*';
    }
    return "count(*) as count, " + groupBy.map(f => {
      console.log("f", f)
      if (f.range) {
        
        return f.range;
      }
      return f.name;
    }
    ).join(", ");
  }

  const groupByFieldsGroupBy = () => {
    if (groupBy.length === 0) {
      return '';
    }
    return 'GROUP BY ' + groupBy.map(f => {
      if (f.alias) {
        return f.alias;
      }
      return f.name;
    }
    ).join(", ");
  }

  const fieldTransformations = (f) => {
    switch (f) {
      case "logLevel":
        return `trim(${f})`;
      // case "logTimestamp":
      //   return `toDateTime64(${f})`
      default:
        return f;
    }
    
  }

  const maybeWrapValue = (v) => {
    console.log('v', v)
    if (v instanceof Date) {
      return `toDateTime64(${v.getTime()}, 3)`;
    }
    if (isNaN(v)) {
      return "'" + v + "'";
    }
    return v;
  }

  const filterToQueryString = ({filter}) => {
    console.log("filter, comparisons", filter, comparisons)
    const filterToUse = comparisons.find(f => f.name === filter.filterName);
    if (!filterToUse) {
      return "N/A -- Unknown Filter";
    }

    if (filterToUse.isFunction) {
      return `${filterToUse.name}(${fieldTransformations(filter.field)} ${filterToUse.expectsValue ? `, ${maybeWrapValue(filter.value)}` : ''})`;
    }

    return `${fieldTransformations(filter.field)} ${filterToUse.name}  ${maybeWrapValue(filter.value)}`;
  }

  const StringFilterBuilder = ({ field, onApply, onCancel }) => {

    const [comparison, setComparison] = useState();
    const [value, setValue] = useState();

    return (
      <div>
        <h3>{field.name}</h3>
        <Picklist
          value={comparison}
          onChange={(e) =>{
            setComparison(comparisons.find(c => c.name === e.name));
          }}
          label="Comparison"
          required
          enableSearch
        >
          {comparisons.filter(c => c.allowedTypes.includes(field.type)).map((c) => (
              <Option name={c.name} label={c.label} />
          ))}
        </Picklist>
        {comparison && comparison.expectsValue && 
        <Input placeholder="value" type="String" value={value} onChange={(e) => setValue(e.target.value)}/>
        }
        <div style={{display: 'flex'}}>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button disabled={
            !comparison || (comparison && comparison.expectsValue && !value)
          }
          onClick={() => {
            onApply(
              {
                field: field.name,
                filterName: comparison.name,
                value: value
              }
            );
          }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }

  const DateTimeFilterBuilder = ({ field, onApply, onCancel }) => {

    const [comparison, setComparison] = useState();
    const [value, setValue] = useState(new Date());

    return (
      <div>
        <h3>{field.name}</h3>
        <Picklist
          value={comparison}
          onChange={(e) =>{
            setComparison(comparisons.find(c => c.name === e.name));
          }}
          label="Comparison"
          required
          enableSearch
        >
          {comparisons.filter(c => c.allowedTypes.includes(field.type)).map((c) => (
              <Option name={c.name} label={c.label} />
          ))}
        </Picklist>
        {comparison && comparison.expectsValue && 
        true
        }
        <DateTimePicker label="DateTime" value={value} onChange={(v) => setValue(v)} formatStyle="large"/>
        <div style={{display: 'flex'}}>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button disabled={
            !comparison || (comparison && comparison.expectsValue && !value)
          }
          onClick={() => {
            onApply(
              {
                field: field.name,
                filterName: comparison.name,
                value: `${value.getFullYear()}-${padZero(value.getMonth() + 1)}-${padZero(value.getDate())} ${padZero(value.getHours())}:${padZero(value.getMinutes())}:${padZero(value.getSeconds())}`
              }
            );
          }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }

  const padZero = (n) => {
    if (n < 10) {
      return `0${n}`;
    }
    return n;
  }

  const FilterBuilder = ({ field, onApply, onCancel }) => {
    // DateTime64, String, Int16
    switch (field.type) {
      case 'String':
      case 'Int16':
        return <StringFilterBuilder field={field} onApply={onApply} onCancel={onCancel}/>
      case 'DateTime64(3)':
        return <DateTimeFilterBuilder field={field} onApply={onApply} onCancel={onCancel}/>
      default: 
      return <></>;
    }
  }

  const FiltersList = () => {
    return (
      <div>
        {filters.filter(f => f.field !== 'appGroup').map(f => 
          <div style={{display: "flex"}}>
            <p>{`${f.field} ${f.filterName} ${f.value}`}</p>
            <Button onClick={() => {
              setFilters(filters.filter(f1 => f1 !== f))
            }}><Remove /></Button>
          </div>
          )}
      </div>
    )
  }

  const GroupByList = () => {
    return (
      <div>
        {groupBy.map(f => 
          <div style={{display: "flex"}}>
            <p>{f.name}</p>
            <Button onClick={() => {
              setGroupBy(groupBy.filter(f1 => f1.name !== f.name))
            }}><Remove /></Button>
          </div>
          )}
      </div>
    )
  }

  const SortInput = () => {
    const [field, setField] = useState(sort.field);
    const [direction, setDirection] = useState(sort.direction);

    return (
      <div>
        <h3>Sort</h3>
        <Picklist
          value={field}
          onChange={setField}
          label="Field"
          enableSearch
        >
          {(groupBy.length > 0 ? [{name: 'count'}, ...fields.filter(f => groupBy.find(g => g.name === f.name) !== undefined)]  : fields).map((c) => (
              <Option name={c.name} label={c.name} />
          ))}
        </Picklist>
        <Picklist
          value={direction}
          onChange={setDirection}
          label="Direction"
        >
          <Option label="⬇ Descending" name="DESC" />
          <Option label="⬆ Ascending" name="ASC" />
        </Picklist>
        <div style={{display: 'flex'}}>
          <Button>Cancel</Button>
          <Button disabled={
            !field
          }
          onClick={() => {
            setSort({
              field: field.name,
              direction: direction.name
            })
          }}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  }

  const FilterInput = () => {
    const [field, setField] = useState(fields[0]);

    const [filtersOpen, setFiltersOpen] = useState(false);

    return (
      <div>
        <h3>Filter</h3>
        {filtersOpen && <Select
          label="Select Field"
          options={
            fields.map((f) => ({value: f.name, label: f.name}))
          }
          value={field}
          onChange={(e) => setField(fields.find(f => f.name === e.target.value))}
        />}
        {
          filtersOpen && field && fields.filter(f => f.name === field.name).map(f => 
          <FilterBuilder
            field={f} 
            onApply={(f) => setFilters([...filters, f])}
            onCancel={() => {
              setField(fields[0]);
              setFiltersOpen(false);
            }}
          />)
        }
        {!filtersOpen && <Button variant="text" onClick={() => setFiltersOpen(true)}>+ Add Filter</Button>}
      </div>
    );
  }

  const GroupByInput = () => {

    const ranges =  [
      {
        label: "Minute",
        value: "toStartOfMinute(logTimestamp) as ts"
      },
      {
        label: "15 Minutes",
        value: "toStartOfFifteenMinutes(logTimestamp) as ts"
      },
      {
        label: "Hour",
        value: "toStartOfHour(logTimestamp) as ts"
      },
      {
        label: "Day",
        value: "toStartOfDay(logTimestamp) as ts"
      }
    ];

    const [field, setField] = useState(fields[0]);

    const [groupByOpen, setGroupByOpen] = useState(false);

    const [range, setRange] = useState(ranges[0].value);

    return (
      <div>
        <h3>Group By</h3>
        {groupByOpen && <Select
          label="Select Field"
          options={
            fields.map((f) => ({value: f.name, label: f.name}))
          }
          
          value={field}
          onChange={(e) => setField(fields.find(f => f.name === e.target.value))}
        />}
        {
          groupByOpen && field && field.name !== 'logTimestamp' &&
          <Button
            onClick={() => { 
              setGroupBy([...groupBy, {name: field.name}]);
              setGroupByOpen(false);
            }}>
              Apply
          </Button>
        }
        {
          groupByOpen && field && field.name === 'logTimestamp' &&
          <>
          <Select
            label="Select Range"
            options={
             ranges
            }
            value={range}
            onChange={(e) => setRange(e.target.value)}
          />
          <Button
            onClick={() => { 
              console.log("range", range)
              setGroupBy([...groupBy, {name: field.name, range: range, alias: 'ts'}]);
              setGroupByOpen(false);
            }}>
              Apply
          </Button>
          </>
        }
        {!groupByOpen && <Button variant="text" onClick={() => setGroupByOpen(true)}>+ Group By</Button>}
      </div>
    );
  }

  return (
    <div style={{textAlign: "left", display: "flex"}} >
      <div>
      <Picklist
          value={logType}
          onChange={(e) =>{
            setLogType({...e, table: e.name});
          }}
          label="Log Type"
          required
          enableSearch
        >
          {logTypes.map((s) => (
              <Option name={s.table} label={s.label} />
          ))}
        </Picklist>
        <Picklist
          value={deployable}
          onChange={setDeployable}
          label="Select Deployable"
          required
          enableSearch
        >
          {deployables.map((s) => (
              <Option name={s} label={s} />
            ))}
        </Picklist>
        <FilterInput />
        <FiltersList />
        <GroupByInput />
        <GroupByList />
        <SortInput />
        <Input label="Limit" placeholder="1000" type="Number" value={limit} onChange={(e) => setLimit(e.target.valueAsNumber)}/>
        <Button style={{marginTop: 100}} onClick={() => runQuery()}>Fetch Logs</Button>
       </div>
       <div>
        <h3 style={{marginLeft: 50}}>Query Builder: {`${queryBuilder}`}</h3>
        {error && 
          <>
          <h1>Error:</h1>
          <p>{error}</p>
          </>
        }
        {
          groupBy.length === 0 && logType.table === 'ApplicationLogs' && 
          <ApplicationLogsViewer logs={logs} isLoading={isLoading}/>
        }
        {
          groupBy.length === 0 && logType.table === 'AccessLogs' && 
          <AccessLogsViewer logs={logs} isLoading={isLoading}/>
        }
        {
          groupBy.length > 0 && logs.length > 0 &&
          <>
            <LogsChart logs={logs} isLoading={isLoading}/>
            <CustomLogViewer logs={logs} isLoading={isLoading}/>
          </>
        }
       </div>
    </div>
  );
}


const CustomLogViewer = ({ logs, isLoading}) => {

  if (isLoading) {
    return (
      <div>
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
      </div>
    );
  }

  const alignment = "center";

  const keys = Object.keys(logs[0]);

  return (
    <div style={{overflowY: "auto", height: 750}}>
      <TableContainer>
        <Table>
          <TableHead>
            {keys.map(k => <TableCell align={alignment}>{k}</TableCell>)}
          </TableHead>
          <TableBody>
            {
              logs.map(log => (
                <TableRow>
                  {keys.map(k => <TableCell align={alignment}>{log[k]}</TableCell>)}
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const AccessLogsViewer = ({ logs, isLoading}) => {

  if (isLoading) {
    return (
      <div>
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
      </div>
    );
  }

  const alignment = "center";

  return (
    <div style={{overflowY: "auto", height: 750}}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableCell>Timestamp</TableCell>
            <TableCell>Method</TableCell>
            <TableCell>Path</TableCell>
            <TableCell>Status Code</TableCell>
            <TableCell>Response Time</TableCell>
            <TableCell>Response Size</TableCell>
            <TableCell>Http Version</TableCell>
            <TableCell>User Agent</TableCell>
            <TableCell>Client IP</TableCell>
            <TableCell>Instance Id</TableCell>
          </TableHead>
          <TableBody>
            {
              logs.map(log => (
                <TableRow>
                  <TableCell align={alignment}>{log.logTimestamp}</TableCell>
                  <TableCell align={alignment}>{log.httpMethod}</TableCell>
                  <TableCell align={alignment}>{log.path}</TableCell>
                  <TableCell align={alignment}>{log.statusCode}</TableCell>
                  <TableCell align={alignment}>{log.responseTime}</TableCell>
                  <TableCell align={alignment}>{log.responseSize}</TableCell>
                  <TableCell align={alignment}>{log.httpVersion}</TableCell>
                  <TableCell align={alignment}>{log.userAgent}</TableCell>
                  <TableCell align={alignment}>{log.ip}</TableCell>
                  <TableCell align={alignment}>{log.instanceId}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const ApplicationLogsViewer = ({ logs, isLoading}) => {

  if (isLoading) {
    return (
      <div>
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
        <ApplicationLogSkeleton />
      </div>
    );
  }

  return (
    <div style={{overflowY: "auto", height: 750}}>
      {logs.map(l => <ApplicationLog log={l} />)}
      {logs.length < 1 && <Card><p style={{textAlign: 'center'}}>No Logs.</p></Card>}
    </div>
  )
}

const ApplicationLog = ({ log }) => {
  return (
    <Card style={{margin: 25, padding: 10}}>
      <div style={{display: "flex"}}>
        <Tooltip title="Timestamp" placement="top-start" style={{margin: 10}}><p>{log.logTimestamp}</p></Tooltip>
        <Tooltip title="App Group" placement="top-start" style={{margin: 10}}><p>{log.appGroup}</p></Tooltip>
        <Tooltip title="Instance Id" placement="top-start" style={{margin: 10}}><p>{log.instanceId}</p></Tooltip>
        
      </div>
     
      <p><LogLevel level={(log.logLevel || "").trim()} /> {log.content}</p>
      {
        log.stackTrace && log.stackTrace !== "" && 
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
          >
            <small>Stack Trace</small>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{backgroundColor: "lightgray", fontSize: 12}}>
              <code>
                {escapedNewLineToLineBreakTag(log.stackTrace)}
              </code>
            </div>
          </AccordionDetails>
        </Accordion>
      }
    </Card>
  )
}

const ApplicationLogSkeleton = () => {
  return (
    <Card style={{height: 100, margin: 25, padding: 10}}>
      <div>
        <div style={{display: "flex"}}>
          <Skeleton variant="rounded" width={"15%"} height={25} style={{marginTop: 5, marginLeft: 5}} />
          <Skeleton variant="rounded" width={"15%"} height={25} style={{marginTop: 5, marginLeft: 10}} />
          <Skeleton variant="rounded" width={"10%"} height={25} style={{marginTop: 5, marginLeft: 10}} />
        </div>
        {/* <Skeleton variant="rounded" width={"40%"} height={25} style={{marginTop: 5, marginLeft: 5}} /> */}
        <div style={{display: "flex"}}>
          <Skeleton variant="rounded" width={"7.5%"} height={35} style={{marginTop: 10, marginLeft: 5}} />
          <Skeleton variant="rounded" width={"50%"} height={35} style={{marginTop: 10, marginLeft: 5}} />
        </div>
      </div>
    </Card>
  )
}

const LogLevel = ({ level }) => {
  switch (level) {
    case 'ERROR': 
      return <b style={{color: "red"}}>{level}</b>;
    case 'WARN': 
      return <b style={{color: "gold"}}>{level}</b>;
    default: 
      return <b>{level}</b>;
  }
}

const escapedNewLineToLineBreakTag = (string) => string.split('\n').map((item, index) => (index === 0) ? item : [<br key={index} />, item])
