import { apiCaller } from "./API";

export const getMonitorsInGroup = async (group) => {
  return apiCaller
    .get(`/watchdog/v1/monitors/group/${group}`)
    .then((d) => d.data)
    .catch((err) => console.error("Error", err));
}

export const getAllMonitors = async (group) => {
  return apiCaller
    .get(`/watchdog/v1/monitors/`)
    .then((d) => d.data)
    .catch((err) => console.error("Error", err));
}