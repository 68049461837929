import './App.css';
import { Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SidebarNavigation from './components/SidebarNavigation';
import Overwatch from './pages/Overwatch';
import ManageDeployable from './pages/ManageDeployable';
import ToolsDashboard from './pages/ToolsDashboard';
import LoadBalancers from './pages/LoadBalancers';
import BucketsViewer from './pages/BucketsViewer';
import LogFetch from './pages/LogFetch';
import { QueryClient, QueryClientProvider } from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import ManageInstance from './pages/ManageDeployableInstance';
import WatchdogOverview from './pages/WatchdogOverview';
import ApiCallerPage from './pages/ApiCallerPage';

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <SidebarNavigation><ToolsDashboard /></SidebarNavigation>
    },
    {
      path: "overwatch",
      element: <SidebarNavigation><Overwatch /></SidebarNavigation>
    },
    {
      path: "overwatch/:deployableId",
      element: <SidebarNavigation><ManageDeployable /></SidebarNavigation>
    },
    {
      path: "overwatch/:deployableId/:instanceId",
      element: <SidebarNavigation><ManageInstance /></SidebarNavigation>
    },
    {
      path: "load-balancers",
      element: <SidebarNavigation><LoadBalancers /></SidebarNavigation>
    },
    {
      path: "logs",
      element: <SidebarNavigation><LogFetch /></SidebarNavigation>
    },
    {
      path: "watchdog",
      element: <SidebarNavigation><WatchdogOverview /></SidebarNavigation>
    },
    {
      path: "buckets",
      element: <SidebarNavigation><BucketsViewer /></SidebarNavigation>,
      children: [{
        path: ":bucket",
        element: <SidebarNavigation><BucketsViewer /></SidebarNavigation>,
        children: [{
          path: ":prefixPath*",
          element: <SidebarNavigation><BucketsViewer /></SidebarNavigation>,
        }]
      }]
    },
    {
      path: "api-caller",
      element: <SidebarNavigation><ApiCallerPage /></SidebarNavigation>
    },
  ]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
      }
    }
  })

  const localStoragePersistor = createWebStoragePersistor({storage: window.localStorage})
  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor
  })

  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        </QueryClientProvider>
      </Suspense>
    </div>
  );
}

export default App;
