import { apiCaller, API_HOST } from "./API";

// const API_HOST = 'http://localhost';

export const listBuckets = (callback) => {
  apiCaller
    .get(`/overwatch/v2/buckets`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const listContentInBucket = (bucket, prefix, callback) => {
  apiCaller
    .get(`/overwatch/v2/buckets/${bucket}?prefix=${prefix || ""}`)
    .then((d) => callback(d.data))
    .catch((err) => console.error("Error", err));
};

export const getContentsOfKey = (bucket, key, callback) => {
  apiCaller
    .get(`/overwatch/v2/buckets/${bucket}/content?key=${key}`)
    .then((d) => callback(d))
    .catch((err) => console.error("Error", err));
};

export const getContentsOfKeyRequestUrl = (bucket, key) => `${API_HOST}/overwatch/v2/buckets/${bucket}/content?key=${key}`;