import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useInterval } from "./Utils";
import { apiCaller } from "../api/API"

export const StandardHttpHealthCheckStatus = ({ value, status, hideLastTimeChecked, onlyCheckStatusOnce }) => {
  const showTimeLastChecked = hideLastTimeChecked === null || !hideLastTimeChecked;

  const [state, setState] = useState(status || "loading");
  const [lastCheck, setLastCheck] = useState(new Date());
  const [d, setD] = useState(0);

  // const url = `http://${value}/health-check`;

  const check = () => {
    console.log("passed in status", status)
    if (status || (onlyCheckStatusOnce && state !== "loading")) {
      return;
    }
    console.log("Still running check...")
    // if (state !== "loading") {
    //   updateState("loading");
    // }
    apiCaller
      .get(
        `/overwatch/v2/meta/api-health-check-proxy?ip=${value}`
      )
      .then(() => {
        updateState("healthy");
      })
      .catch(() => {
        updateState("unhealthy");
      })
      .finally(() => setLastCheck(new Date()));
  };

  const updateState = (nextState) => {
    if (state === nextState) {
      // If they are the same state, don't do anything
      return;
    }
    setState(nextState);
  }

  useInterval(check, 5000);

  useInterval(() => {
    setD(Math.floor((new Date() - lastCheck) / 1000));
  }, 2000);

  useEffect(() => check(), []);

  const Status = (s) => {
    switch (s.state) {
      default:
      case "loading":
        return <CircularProgress style={{ color: "#6A54F3" }} />;
      case "healthy":
        return <CheckCircleIcon style={{ color: "green" }} />;
      case "unhealthy":
        return <CloseRoundedIcon style={{ color: "red" }} />;
    }
  };

  return (
    <div>
      <div style={{ marginLeft: "25%", marginTop: "5%" }}>
        <Status state={state} />
      </div>
      {!hideLastTimeChecked && <div style={{ marginLeft: "10%" }}>{`Last Checked ${d}s ago`}</div>}
    </div>
  );
};
