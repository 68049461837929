import { useEffect, useState } from "react";
import RadioButtonGroup from "react-rainbow-components/components/RadioButtonGroup";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import Modal from "react-rainbow-components/components/Modal";
import Picklist from "react-rainbow-components/components/Picklist";
import Option from "react-rainbow-components/components/Option";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getListOfEc2MachineTypes,
  isApiService,
  isFrontEnd,
  listBuildTriggers,
  upsertDeployable,
} from "../api/OverwatchApi";
import BuildTriggersSelector from "../components/BuildTriggersSelector";
import { Switch } from "@mui/material";

export const DeployableInputModal = (params) => {
  let { model } = params;

  const [isSaving, setIsSaving] = useState(false);

  const environmentOptions = [
    { value: "QA", label: "QA" },
    { value: "PROD", label: "PROD" },
  ];

  const typeOptions = [
    { value: "SERVICE", label: "Service" },
    { value: "WORKER", label: "Worker" },
    // { value: "MOBILE_REACT_NATIVE", label: "React Native" },
    // { value: "REACT_WEB_UI", label: "Web Front End" },
    { value: "MEMCACHED", label: "Memcached" },
    {value: "LOAD_BALANCER", label: "Load Balancer"}
  ];

  const [machineTypeOptions, setMachineTypeOptions] = useState([]);
  const [buildTriggers, setBuildTriggers] = useState([]);

  const [selectedEnvironment, setSelectedEnvironment] = useState();
  const [selectedType, setSelectedType] = useState();
  const [name, setName] = useState();
  const [dockerUrl, setDockerUrl] = useState();
  const [appGroup, setAppGroup] = useState();
  const [messagesSubscriptionId, setMessagesSubscriptionId] = useState();
  const [cloudBuildTriggerName, setCloudBuildTriggerName] = useState();
  const [cloudBuildTriggerId, setCloudBuildTriggerId] = useState();
  const [betterUptimeBadge, setBetterUptimeBadge] = useState();
  const [machineType, setMachineType] = useState();
  const [dockerArgs, setDockerArgs] = useState();
  const [securityGroupId, setSecurityGroupId] = useState();
  const [appRoot, setAppRoot] = useState();
  //rhouldAutoDeploy
  const [autoDeploy, setAutoDeploy] = useState();
  //requireCanaryTestsToPass
  const [requireCanaryTestsToPass, setRequireCanaryTestsToPass] = useState();

  const [monitoringConfig, setMonitoringConfig] = useState();

  useEffect(() => {
    getListOfEc2MachineTypes(setMachineTypeOptions);
    listBuildTriggers(setBuildTriggers);
  }, []);

  useEffect(() => {
    console.log("Model", model);
    if (!model || Object.keys(model).length === 0) {
      return;
    }

    setName(model.name);
    setDockerUrl(model.dockerContainerUrl);
    setAppGroup(model.appGroup);
    setMessagesSubscriptionId(model.messagesSubscriptionId);
    setSelectedEnvironment(model.environment);
    setMachineType(
      machineTypeOptions.filter((m) => m.name === model.machineType)[0]
    );
    setSelectedType(model.type);
    setCloudBuildTriggerId(model.cloudBuildTriggerId);
    setCloudBuildTriggerName(model.cloudBuildTriggerName);
    setBetterUptimeBadge(model.betterUptimeBadge);
    setDockerArgs(model.dockerArgs);
    setSecurityGroupId(model.securityGroupId);
    setMonitoringConfig(model.monitoringConfig);
    setAppRoot(model.appRoot);
    setAutoDeploy(model.shouldAutoDeploy);
    setRequireCanaryTestsToPass(model.requireCanaryTestsToPass);
  }, [model, machineTypeOptions]);

  return (
    <Modal
      id="modal-1"
      {...params}
      onRequestClose={() => {
        // Callback
        params.onClose();
      }}
    >
      <h1>{model ? "Edit" : "Add New"} Deployable</h1>
      <RadioButtonGroup
        id="radio-button-group-component-1"
        options={typeOptions}
        value={selectedType}
        variant="brand"
        onChange={(e) => setSelectedType(e.target.value)}
        label="Select Deployable Type"
        style={{ width: 500 }}
      />
      <Input
        label="Deployable Name"
        placeholder="Enter name of deployable"
        type="text"
        className="rainbow-p-around_medium"
        style={{ width: 300, padding: "5%" }}
        value={name}
        onChange={(v) => setName(v.target.value)}
      />
      {!isFrontEnd(selectedType) && (
        <Input
          label="Docker Container Image Url"
          placeholder="docker.io"
          type="url"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={dockerUrl}
          onChange={(v) => setDockerUrl(v.target.value)}
        />
      )}
      {!isFrontEnd(selectedType) && (
        <Input
          label="Docker Args"
          placeholder="-p 80:80"
          type="url"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={dockerArgs}
          onChange={(v) => setDockerArgs(v.target.value)}
        />
      )}
      {!isFrontEnd(selectedType) && (
        <Input
          label="EC2 Security Group Id"
          placeholder="sg-01a12cf1e8736630a"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={securityGroupId}
          onChange={(v) => setSecurityGroupId(v.target.value)}
        />
      )}
      {!isFrontEnd(selectedType) && (
        <Input
          label="App Group"
          placeholder="Enter deployable's App Group"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={appGroup}
          onChange={(v) => setAppGroup(v.target.value)}
        />
      )}
      {isApiService(selectedType) && (
        <Input
          label="App Api Root"
          placeholder="/app"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={appRoot}
          onChange={(v) => setAppRoot(v.target.value)}
        />
      )}
      {!isFrontEnd(selectedType) && (
        <Input
          label="MessagesSubscriptionId"
          placeholder="MessagesSubscriptionId"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={messagesSubscriptionId}
          onChange={(v) => setMessagesSubscriptionId(v.target.value)}
        />
      )}
      <>
      <BuildTriggersSelector
          buildTriggers={buildTriggers}
          preselectedTrigger={buildTriggers.find(
            (t) => t.id === cloudBuildTriggerId
          )}
          onSelect={(selectedTrigger) => {
            setCloudBuildTriggerName(selectedTrigger.name);
            setCloudBuildTriggerId(selectedTrigger.id);
          }}
        />
        <Input
          label="Build Trigger Name"
          placeholder="CloudBuildTriggerName"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={cloudBuildTriggerName}
          onChange={(v) => setCloudBuildTriggerName(v.target.value)}
        />
        <Input
          label="Build Trigger ID"
          placeholder="builder-trigger-id"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={cloudBuildTriggerId}
          onChange={(v) => setCloudBuildTriggerId(v.target.value)}
        />
        <Input
          label="Better Uptime Badge"
          placeholder="https://betteruptime.com/status-badges/v1/monitor/monitor.svg"
          type="text"
          className="rainbow-p-around_medium"
          style={{ width: 300, padding: "5%" }}
          value={betterUptimeBadge}
          onChange={(v) => setBetterUptimeBadge(v.target.value)}
        />
      </>
      <RadioButtonGroup
        id="radio-button-group-component-1"
        options={environmentOptions}
        value={selectedEnvironment}
        variant="brand"
        onChange={(e) => setSelectedEnvironment(e.target.value)}
        label="Select Environment"
        style={{ width: 300, padding: "5%" }}
      />
      {!isFrontEnd(selectedType) && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Advanced Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Picklist
              id="picklist-1"
              //style={containerStyles}
              onChange={setMachineType}
              value={machineType}
              label="Select Machine Type"
            >
              {machineTypeOptions.map((m) => (
                <Option
                  name={m.name}
                  label={`${m.name} [${m.info.cpus} CPUs | ${m.info.memoryGb} GB Memory | ${m.info.networkPerformance} Network]`}
                />
              ))}
            </Picklist>
          </AccordionDetails>
        </Accordion>
      )}
      {isApiService(selectedType) && (
       // toggle to control if canary tests are required to pass
       <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          margin: "10px 0",
        }}
       >
        <Typography>Require Canary Tests to Pass</Typography>
        <Switch
          sx={{
            color: "primary",
            "&.Mui-checked": {
              color: "primary",
            },

          }}
          checked={requireCanaryTestsToPass}
          onChange={(e) => setRequireCanaryTestsToPass(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        </div>
      )}
      {!isFrontEnd(selectedType) && (
       // toggle to control if auto deploy is enabled
       <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
         padding: "10px",
         border: "1px solid #ccc",
         borderRadius: "5px",
         margin: "10px 0",
       }}
      >
       <Typography>Auto Deploy</Typography>
        <Switch
          checked={autoDeploy}
          onChange={(e) => setAutoDeploy(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
        </div>
      )}
      <Button
        id="button-1"
        variant="neutral"
        label={model ? "Save" : "Create"}
        disabled={!name}
        style={{ width: 300, margin: "5%" }}
        isLoading={isSaving}
        onClick={() => {
          setIsSaving(true);
          upsertDeployable(
            isFrontEnd(selectedType)
              ? {
                  name: name,
                  environment: selectedEnvironment,
                  type: selectedType,
                  cloudBuildTriggerName: cloudBuildTriggerName,
                  cloudBuildTriggerId: cloudBuildTriggerId,
                  betterUptimeBadge: betterUptimeBadge
                }
              : {
                  name: name,
                  dockerContainerUrl: dockerUrl,
                  dockerArgs: dockerArgs,
                  securityGroupId: securityGroupId,
                  environment: selectedEnvironment,
                  type: selectedType,
                  machineType: machineType.name,
                  appGroup: appGroup,
                  messagesSubscriptionId: messagesSubscriptionId,
                  cloudBuildTriggerName: cloudBuildTriggerName,
                  cloudBuildTriggerId: cloudBuildTriggerId,
                  betterUptimeBadge: betterUptimeBadge,
                  monitoringConfig: monitoringConfig,
                  appRoot: appRoot,
                  shouldAutoDeploy: autoDeploy,
                  requireCanaryTestsToPass: requireCanaryTestsToPass
                },
            () => {
              setIsSaving(false);
              params.onClose();
              if (!model) {
                setName();
                setDockerUrl();
                setMachineType({ name: "T2_MICRO" });
              }
            }
          )
          }
        }
      />
    </Modal>
  );
};
