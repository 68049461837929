import { Card, Grid } from "@mui/material";
import { TENIT_PRIMARY_COLOR } from "../Colors";

export default function ToolsDashboard() {
  //https://s2.googleusercontent.com/s2/favicons?domain_url=https://www.stackoverflow.com

  const tools = [
    {
      name: "PreFly",
      url: "https://app.prefly.io"
    },
    {
      name: "Tenit X",
      url: "https://app.tenitx.com"
    }
  ]

  return (
    <div style={{}}>

        <Grid container columns={{xs: 1, sm: 2, md: 2}} rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            style={{}}
        >
            {tools.map(t => <ToolCard tool={t} />)}
        </Grid>
    </div>
)
}

const ToolCard = ({ tool }) => {

return  (
    <a style={{textDecoration: "none"}} href={tool.url} >
    <Card
        style={{padding: 10, margin: 10}}
    >
        <img src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${tool.url}`} alt={`${tool.name} favicon`} width={32} style={{borderRadius: 25}} />
        <h2 style={{color: TENIT_PRIMARY_COLOR}}>{tool.name}</h2>
        {/* <Button style={{padding: 10, margin: 10}} >Load Prerendered Page</Button> */}
    </Card>
    </a>
);
}
