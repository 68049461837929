import { useEffect, useState } from "react";
import TableWithBrowserPagination from "react-rainbow-components/components/TableWithBrowserPagination";
import Column from "react-rainbow-components/components/Column";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import { Avatar, Chip, Link, List, ListItemAvatar } from "@mui/material";
import { ServiceNameWithLogo } from "../components/ServiceNameWithLogo";
import { getListOfDeployables, getLoadBalancerInfo } from "../api/OverwatchApi";
import { useNavigate, useParams } from "react-router";
import { DeployableInputModal } from "./DeployableInputModal";
import { TENIT_PRIMARY_COLOR, TENIT_SECONDARY_COLOR } from "../Colors";
import TreeView from '@mui/lab/TreeView';
import { TreeItem } from "@mui/lab";
import { ArrowDownward, ArrowUpward, Article, ChevronRight, Folder, Storage } from "@mui/icons-material";
import { StandardHttpHealthCheckStatus } from "../components/StandardHttpHealthCheckStatus";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { getContentsOfKeyRequestUrl, listBuckets, listContentInBucket } from "../api/BucketsApi";

export default function BucketsViewer() {

  const { bucket, prefixPath } = useParams();

  const [loadBalancers, setLoadBalancers] = useState([]);
  const [search, setSearch] = useState();

  const [addDeployableModalOpen, setAddDeployableModelOpen] = useState(false);

  const [isFetchingDeployables, setIsFetchingDeployables] = useState(true);

  const history = useNavigate();

  const [buckets, setBuckets] = useState([]);
  const [bucketContent, setBucketContent] = useState({
    prefixes: [],
    keys: []
  });
  const [prefix, setPrefix] = useState();
  const [contentUrl, setContentUrl] = useState();

  useEffect(
    () => {
      if (!bucket) {
        listBuckets((b) => {console.log("b", b); setBuckets(b.buckets)})
      }
    }, []
  );

  useEffect(
    () => {
      if (prefixPath !== prefix) {
        setPrefix(prefixPath);
        listContentInBucket(bucket, prefixPath, (c) => setBucketContent(c))
        return;
      }
      if (bucket) {
        listContentInBucket(bucket, prefix, (c) => setBucketContent(c))
      }
    }, [prefix, prefixPath]
  );



  const bucketsList = () => {
    return (
      buckets.map(b => 
        <ListItem alignItems="flex-start">
        <Link underline="hover" color="inherit" href={`/buckets/${b}`}>
        <ListItemAvatar>
          <Avatar>
            <Storage />
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
          primary={b} 
          // secondary="Jan 9, 2014" 
        />
        </Link>
      </ListItem>
      )
    );
  }

  const bucketsKeysList = () => {
    return (
      bucketContent.keys.map(b => 
        <ListItemButton alignItems="flex-start"
          onClick={() => setContentUrl(getContentsOfKeyRequestUrl(bucket, b))}
        >
        <ListItemAvatar>
          <Avatar>
            <Article />
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
          primary={b} 
          // secondary="Jan 9, 2014" 
        />
      </ListItemButton>
      )
    );
  }

  const bucketsFoldersList = () => {
    return (
      bucketContent.prefixes.map(b => 
      <ListItem alignItems="flex-start">
      <Link underline="hover" color="inherit" href={`/buckets/${bucket}/${b}`}>
        <ListItemAvatar>
          <Avatar>
            <Folder />
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
          primary={b} 
          // secondary="Jan 9, 2014" 
        />
        </Link>
      </ListItem>
      )
    );
  }

  const pathBreadcrumb = () => {

    if (!bucket) {
      return (
        <Breadcrumbs >
        <Link underline="hover" color="inherit" href={`/buckets`}>
            Buckets
        </Link>
        </Breadcrumbs>
      );
    }

    const crumbParts = [bucket, ...(prefix || "").split("/")];
    let progression = "";
    const crumbs = [];
    crumbParts.forEach(part => {
      progression += `/${part}`;
      crumbs.push({
        label: part,
        path: progression
      })
    });

    return (
      <Breadcrumbs >
      <Link underline="hover" color="inherit" href={`/buckets`}>
          Buckets
      </Link>
      {
        crumbs.map(crumb => 
          <Link underline="hover" color="inherit" href={`/buckets${crumb.path}`}>
            {crumb.label}
          </Link>
      )
      }
      </Breadcrumbs>
    )
  }

  return (
    <div style={{textAlign: "left", display: "flex"}} >
      <div>
      {pathBreadcrumb()}
       {bucket ? 
       <List sx={{ width: '100%', maxWidth: 750}}>
          { bucketsFoldersList() }
          { bucketsKeysList() }
       </List>
       : 
       <List sx={{ width: '100%', maxWidth: 750}}>
          { bucketsList() }
       </List>}
       </div>
       <div style={{margin: 100}}>
        {
          contentUrl && 
          <iframe height={500} width={500} src={contentUrl}/>
        }
       </div>
    </div>
  );
}
