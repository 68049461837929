import { memo, useEffect, useState } from "react";
import TableWithBrowserPagination from "react-rainbow-components/components/TableWithBrowserPagination";
import Column from "react-rainbow-components/components/Column";
import RadioButtonGroup from "react-rainbow-components/components/RadioButtonGroup";
import Input from "react-rainbow-components/components/Input";
import Button from "react-rainbow-components/components/Button";
import Modal from "react-rainbow-components/components/Modal";
import {
  asyncDeploy,
  bounce,
  deploy,
  getActiveDeploys,
  getBuildsForTrigger,
  getCompletedDeploys,
  getDeployable,
  getListOfInstancesForDeployable,
  isFrontEnd,
  isMobileApp,
  startBuildForTrigger,
} from "../api/OverwatchApi";
import { useNavigate, useParams } from "react-router";
import { AwsInstanceStatus } from "../components/AwsInstanceStatus";
import { StandardHttpHealthCheckStatus } from "../components/StandardHttpHealthCheckStatus";
import { useInterval } from "../components/Utils";
import { LoadBalancerInclusionStatus } from "../components/LoadBalancerInclusionStatus";
import { DeployableInputModal } from "./DeployableInputModal";
import { GCBBuildStatus } from "../components/GCBBuildStatus";
import { Warning } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useQueryClient } from "react-query";
import { ActiveDeploys, MemoizedDeploys } from "../components/ActiveDeploys";

export default function ManageDeployable() {
  const scaleDownStrategyOptions = [
    { value: "KILL_OLDEST_FIRST", label: "Kill oldest first" },
    { value: "KILL_NEWEST_FIRST", label: "Kill newest first" },
    { value: "RANDOM", label: "Random" },
  ];

  const strategyOptions = [
    { value: "BOUNCE_EXISTING", label: "Bounce Existing" },
    { value: "CREATE_FRESH", label: "Create Fresh" },
  ];

  const [deployable, setDeployable] = useState({});
  const [instances, setInstances] = useState([]);
  const [activeDeploys, setActiveDeploys] = useState([]);
  const [completedDeploys, setCompletedDeploys] = useState([]);

  const [builds, setBuilds] = useState([]);

  const [deployModalOpen, setDeployModalOpen] = useState(false);
  const [addDeployableModalOpen, setAddDeployableModelOpen] = useState(false);

  const [scaleDownStrategy, setScaleDownStrategy] = useState(
    scaleDownStrategyOptions[0].value
  );
  const [strategy, setStrategy] = useState(strategyOptions[0].value);
  const [instanceCount, setInstanceCount] = useState();

  const [isDeployRequestLoading, setIsDeployRequestLoading] = useState(false);
  const [isBuildTriggerLoading, setIsBuildTriggerLoading] = useState(false);
  const [isFetchingInstances, setIsFetchingInstances] = useState(true);
  const [bounceLoading, setBounceLoading] = useState(false);

  const [deployOnceBuildFinishes, setDeployOnceBuildFinishes] = useState(false);

  const [metricsUrl, setMetricsUrl] = useState();

  const queryClient = useQueryClient();

  const history = useNavigate();

  const { deployableId } = useParams();

  const loadData = async () => {
    if (deployable.cloudBuildTriggerId) {
      getBuildsForTrigger(deployable.cloudBuildTriggerId, setBuilds);
      setIsFetchingInstances(false);
    }
    getListOfInstancesForDeployable(deployableId, deployable.type === "SERVICE", (e) => {
      setInstances(filteredInstances(e));
      setIsFetchingInstances(false);
    });

    getActiveDeploys(deployableId, (e) => {
      console.log("Active deploys", e);
      setActiveDeploys(e);
    }
    );

    getCompletedDeploys(deployableId, (e) => {
      console.log("Completed deploys", e);
      setCompletedDeploys(e);
    }
    );

  }

  useEffect(() => {
    loadData();
  }, [deployable, deployableId]);
  useEffect(() => getDeployable(deployableId, setDeployable), [deployableId]);

  useInterval(() => {
   loadData();
  }, 15000); // TODO: Maybe increase this value

  useEffect(() => {
    if (deployable.type === "WORKER") {
      setMetricsUrl(
        `https://tenitx.grafana.net/d/e1ce6f50-6d0e-4924-9476-da544df1b867/pubsub-worker?&refresh=1m&var-app_group=${deployable.appGroup}&var-queue=${deployable.messagesSubscriptionId}&from=now-1h&to=now`
      );
      return;
    }

    setMetricsUrl(
      `https://tenitx.grafana.net/d/XvBF6_ynk/http-service-dashboard?orgId=1&refresh=1m&var-app_group=${deployable.appGroup}&from=now-1h&to=now`
    );
  }, [deployable]);

  useEffect(() => {
    //WORKING
    if (deployOnceBuildFinishes) {
      const build = builds.find((b) => b.id === deployOnceBuildFinishes);
      if (!build) {
        console.log("Couldn't find build for id ", deployOnceBuildFinishes);
        setDeployOnceBuildFinishes();
      }

      if (build.status === "SUCCESS") {
        console.log("Deploying new build...");
        setBounceLoading(true);
        bounce(deployableId, () => {
          setDeployOnceBuildFinishes();
          setBounceLoading(false);
        });
      }
    }
  }, [builds]);


  const MonitoringConfig = ({ config }) => {
    if (!config) {
      return (
        <div style={{ display: "flex", color: "orange" }}>
          <Warning style={{ paddingRight: 10 }} />
          <>Warning: This deployable is not monitored</>
        </div>
      );
    }

    return (
      <>
        <h2>Monitoring Config</h2>
        <p>{`Monitor Type: ${config.monitorType}`}</p>
        <p>{`Monitoring Style: ${config.monitoringStyle}`}</p>
        {config.monitorId && <p>{`Monitor Id: ${config.monitorId}`}</p>}
        {config.monitorGroupId && (
          <p>{`Monitor Group Id: ${config.monitorGroupId}`}</p>
        )}
        {config.heartbeatId && <p>{`Heartbeat Id: ${config.heartbeatId}`}</p>}
        {config.heartbeatGroupId && (
          <p>{`Heartbeat Group Id: ${config.heartbeatGroupId}`}</p>
        )}
        {config.urlToMonitorPattern && (
          <p>{`Url Monitor Pattern: ${config.urlToMonitorPattern}`}</p>
        )}
      </>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <Button
          id="button-1"
          variant="brand"
          label="Back"
          onClick={() => history(`/overwatch`)}
          style={{ marginLeft: 10 }}
        />
        {!isFrontEnd(deployable.type) && (
          <Button
            id="button-1"
            variant="brand"
            label="Bounce Instances"
            //onClick={() => setDeployModalOpen(true)}
            style={{ marginLeft: "40%" }}
          />
        )}
        <Button
          id="button-1"
          variant="brand"
          label="Deploy"
          onClick={() => setDeployModalOpen(true)}
          style={{ marginLeft: "auto", marginRight: 10 }}
        />
      </div>
      {Object.keys(deployable).length > 0 && (
        <div>
          <h1>{deployable.name}</h1>
          <div style={{ textAlign: "left", marginLeft: 10 }}>
            <p>{`Type: ${deployable.type}`}</p>
            <p>{`Environment: ${deployable.environment}`}</p>
            {deployable.appRoot && <p>{`App Root: ${deployable.appRoot}`}</p>}
            {!isFrontEnd(deployable.type) ? (
              <>
                <p>{`Container Url: ${deployable.dockerContainerUrl}`}</p>
                {deployable.dockerArgs && <p>{`Container Args: ${deployable.dockerArgs}`}</p>}
                <p>{`EC2 Machine Type: ${deployable.machineType
                  .replaceAll("_", ".")
                  .toLowerCase()}`}</p>
                <p>{`CPU(s): ${deployable.machineInfo.cpus}`}</p>
                <p>{`Memory: ${deployable.machineInfo.memoryGb}GB`}</p>
                <p>{`Network Performance: ${deployable.machineInfo.networkPerformance
                  .replaceAll("_", " ")
                  .toLowerCase()}`}</p>
              </>
            ) : (
              <>
                <p>{`Build Trigger Name: ${deployable.cloudBuildTriggerName}`}</p>
                <p>{`Build Trigger Id: ${deployable.cloudBuildTriggerId}`}</p>
              </>
            )}
          </div>
          <div style={{ display: "flex", margin: 1 }}>
            <Button
              style={{ margin: 1 }}
              variant="brand"
              label="Edit"
              onClick={() => setAddDeployableModelOpen(true)}
            />
            {!isFrontEnd(deployable.type) && (
              <>
                <a
                  style={{ textDecoration: "none", margin: 1 }}
                  href={`/internal/logs?appGroup=${deployable.appGroup}&env=${deployable.environment}&timeValue=15&timeScale=MINUTES`}
                >
                  <Button variant="brand" label="Logs" />
                </a>
                <a
                  style={{ textDecoration: "none", margin: 1 }}
                  href={metricsUrl}
                >
                  <Button variant="brand" label="Metrics" />
                </a>
              </>
            )}
          </div>
        </div>
      )}
      <DeployableInputModal
        model={deployable}
        isOpen={addDeployableModalOpen}
        onClose={() => setAddDeployableModelOpen(false)}
      />
      <Modal
        id="modal-1"
        isOpen={deployModalOpen}
        onRequestClose={() => setDeployModalOpen(false)}
      >
        <h1 style={{ textAlign: "center" }}>Deploy</h1>
        {!isFrontEnd(deployable.type) ? (
          <>
            <Input
              label="Instance Count"
              placeholder="Number of Instances"
              type="number"
              className="rainbow-p-around_medium"
              style={{ width: "100%", paddingBottom: "5%" }}
              value={instanceCount || instances.length}
              onChange={(v) => setInstanceCount(v.target.value)}
            />
            <RadioButtonGroup
              id="radio-button-group-component-1"
              options={strategyOptions}
              value={strategy}
              variant="brand"
              onChange={(e) => setStrategy(e.target.value)}
              label="Deploy Strategy"
              style={{ width: "100%", paddingBottom: "5%" }}
            />
            {instanceCount && instanceCount < instances.length && (
              <RadioButtonGroup
                id="radio-button-group-component-1"
                options={scaleDownStrategyOptions}
                value={scaleDownStrategy}
                variant="brand"
                onChange={(e) => setScaleDownStrategy(e.target.value)}
                label="Scale down strategy"
                style={{ width: "100%", paddingBottom: "5%" }}
              />
            )}
            <Button
              id="button-1"
              variant="neutral"
              label="Deploy"
              isLoading={isDeployRequestLoading}
              disabled={!instanceCount && !instances.length}
              style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}
              onClick={() => {
                setIsDeployRequestLoading(true);
                deploy(
                  deployableId,
                  {
                    instanceCount: instanceCount || instances.length,
                    strategy: strategy,
                    scaleDownStrategy: scaleDownStrategy,
                  },
                  () => {
                    setInstanceCount();
                    setDeployModalOpen(false);
                    setIsDeployRequestLoading(false);
                  }
                );
              }}
            />
            <Button
              id="button-1"
              variant="neutral"
              label="Deploy Async"
              isLoading={isDeployRequestLoading}
              disabled={!instanceCount && !instances.length}
              style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}
              onClick={() => {
                setIsDeployRequestLoading(true);
                asyncDeploy(
                  deployableId,
                  strategy,
                  {
                    // instanceCount: instanceCount || instances.length,
                    // strategy: strategy,
                    // scaleDownStrategy: scaleDownStrategy,
                  },
                  () => {
                    setInstanceCount();
                    setDeployModalOpen(false);
                    setIsDeployRequestLoading(false);
                  }
                );
              }}
            />
          </>
        ) : (
          <Button
            id="button-1"
            variant="neutral"
            label="Deploy"
            isLoading={isDeployRequestLoading}
            style={{ width: "100%", marginBottom: "5%", marginTop: "5%" }}
            onClick={() => {
              setIsDeployRequestLoading(true);
              startBuildForTrigger(
                deployable.cloudBuildTriggerName,
                // TODO: Figure out how to get branch names
                isMobileApp(deployable.type) ? "main" : "master",
                () => {
                  setInstanceCount();
                  setDeployModalOpen(false);
                  setIsDeployRequestLoading(false);
                }
              );
            }}
          />
        )}
      </Modal>
      {!isFrontEnd(deployable.type) && (
        <TableWithBrowserPagination
          isLoading={isFetchingInstances}
          // style={{ width: "100%" }}
          pageSize={10}
          data={instances}
          keyField="instanceId"
        >
          <Column header="Name" field="instanceId" />
          {/* <Column header="Status" field="status" component={StatusBadge} /> */}
          <Column header="status" field="state" component={AwsInstanceStatus} />
          {["SERVICE", "LOAD_BALANCER", "WORKER"].includes(deployable.type || "NA") && (
            <Column
              header="Health Status"
              field="healthStatus"
              alignment="center"
              component={(e) => 
              
                deployable.type === "WORKER" ?
                <img height={50} src={`https://watchdog-badge.tenit.workers.dev/${deployable.appGroup}/${e.row.instanceId}.svg?t=${Date.now()}`} alt="Worker health status" /> :
                <StandardHttpHealthCheckStatus status={e.row.healthStatus} />
              
            }
            />
          )}
          <Column header="External IP" field="publicDnsName" />
          {(deployable.type || "WORKER") === "SERVICE" && (
            <Column
              header="Load Balancer"
              field="loadBalancerInclusion"
              component={LoadBalancerInclusionStatus}
            />
            // <></>
          )}
          <Column
            header="Launch Time"
            field="launchTime"
            component={(e) => (
              <p>
                {new Date(e.row.launchTime).toLocaleTimeString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            )}
          />
          <Column
            header=""
            field="dockerUrl"
            component={(e) => {
              return (
                <Button
                  id="button-2"
                  variant="neutral"
                  label="Manage"
                  onClick={() =>
                    history(`/overwatch/${deployableId}/${e.row.instanceId}`)
                  }
                />
              );
            }}
          />
        </TableWithBrowserPagination>
      )}
      {activeDeploys.length > 0 && 
          <MemoizedDeploys deploys={activeDeploys} isLoading={isFetchingInstances} />
      }
      {completedDeploys.length > 0 && 
          <MemoizedDeploys deploys={completedDeploys} isLoading={isFetchingInstances} isActiveDeploys={false} />
      }
      {deployable.cloudBuildTriggerId && (
        <>
          <Button
            id="button-1"
            variant="border"
            label="Build Now"
            isLoading={isBuildTriggerLoading}
            style={{ width: "20%", textAlign: "left"}}
            onClick={() => {
              setIsBuildTriggerLoading(true);
              startBuildForTrigger(
                deployable.cloudBuildTriggerName,
                // TODO: Figure out how to get branch names
                "main",
                // isMobileApp(deployable.type) ? "main" : "master",
                () => {
                  setIsBuildTriggerLoading(false);
                }
              );
            }}
          />
        <TableWithBrowserPagination
          isLoading={isFetchingInstances}
          style={{ width: "100%" }}
          pageSize={5}
          data={builds.slice(0, 15)}
          keyField="id"
        >
          <Column header="Build Id" field="id" />
          <Column
            header="Status"
            field="status"
            component={(e) => (
              <GCBBuildStatus
                value={e.row.status}
                isFrontEnd={isFrontEnd(deployable.type)}
              />
            )}
          />
          {/* {component={AwsInstanceStatus} />} */}
          <Column
            header="Build Duration"
            field="startTime"
            component={(e) => {
              const time =
                (e.row.finishTime ? new Date(e.row.finishTime) : Date.now()) -
                new Date(e.row.startTime).getTime();

              return <p>{millisToMinutesAndSeconds(time)}</p>;
            }}
          />
          <Column
            header={isFrontEnd(deployable.type) ? "Deploy Time" : "Build Time"}
            field="finishTime"
            component={(e) => (
              <p>
                {new Date(
                  e.row.finishTime || e.row.startTime
                ).toLocaleTimeString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            )}
          />
          <Column
            header=""
            component={(e) => {
              return (
                <a href={e.row.logUrl}>
                  <Button
                    id="button-2"
                    variant="neutral"
                    label="Build Log"
                    //disabled={isFrontEnd(deployable.type)}
                  />{" "}
                </a>
              );
            }}
          />
          {builds.length > 0 && builds[0].status === "WORKING" && (
            <Column
              header=""
              component={(e) => {
                return (
                  <>
                    {(e.row.status === "WORKING" ||
                      e.row.id === deployOnceBuildFinishes) && (
                      <Button
                        disabled={bounceLoading}
                        isLoading={bounceLoading}
                        onClick={() => {
                          if (e.row.id === deployOnceBuildFinishes) {
                            setDeployOnceBuildFinishes();
                          } else {
                            setDeployOnceBuildFinishes(e.row.id);
                          }
                        }}
                      >
                        {e.row.id === deployOnceBuildFinishes
                          ? `Cancel Auto-deploy`
                          : `Deploy once build is done`}
                      </Button>
                    )}
                  </>
                );
              }}
            />
          )}
        </TableWithBrowserPagination>
        </>
      )}
    </div>
  );
}

const filteredInstances = (instances) =>
  instances.filter((i) => i.state !== "terminated");

function millisToMinutesAndSeconds(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}
