import { Chip } from "@mui/material";

export const AwsInstanceStatus = (params) => {
  const { value } = params;

  const Status = () => {
    switch (value) {
      case "running":
        return (
          <Chip
            style={{ backgroundColor: "Green", color: "white", width: "50%" }}
            label="Running"
          />
        );
      case "pending":
        return (
          <Chip
            variant="outlined"
            style={{ borderColor: "black", color: "black", width: "50%" }}
            label="Pending"
          />
        );
      case "terminated":
        return (
          <Chip
            style={{ backgroundColor: "grey", color: "white", width: "50%" }}
            label="Terminated"
          />
        );
      case "shutting-down":
      case "stopping":
        return (
          <Chip
            variant="outlined"
            style={{ borderColor: "red", color: "red", width: "50%" }}
            label="Shutting Down"
          />
        );
      case "stopped":
        return (
          <Chip
            variant="outlined"
            style={{ backgroundColor: "red", color: "white", width: "50%" }}
            label="Stopped"
          />
        );
    }
    return <></>;
  };

  return <Status />;
};
