import { BarChart, LineChart } from "@mui/x-charts";


const TIME = "ts";
const COUNT = "count";

export function LogsChart({logs, isLoading}) {

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (!logs) {
        return <div>No logs</div>
    }

    /* 
    data is formatted like this [{ts: '2021-10-01', count: 100, statusCode: 400}, {ts: '2021-10-02', count: 100, statusCode: 200}]
    build function map it to something like this series={[
    { data: pData, label: '400' },
    { data: uData, label: '200' },
  ]}
  */

  const [data, times]  = buildDataSeries(logs);

    return (
        <div>
            <h1>Logs Chart</h1>
            <LineChart
                height={400}
                sx={{ padding: 1 }}
                width={1500}
                // width={800}
                series={data}
                xAxis={[{ scaleType: 'point', data: times }]}
                slots={{ xAxis: { legend: { onClick : (() => alert("Click"))  } } } }
                skipAnimation
                // slotProps={{ legend: { onClick : (() => alert("Click"))  } }}
            />
            <BarChart
                height={400}
                sx={{ padding: 1 }}
                width={1500}
                skipAnimation
                series={data}
                xAxis={[{ scaleType: 'band', data: times }]}
                slots={{ xAxis: { legend: { onClick : (() => alert("Click"))  } } } }
                // slotProps={{ legend: { onClick : (() => alert("Click"))  } }}   
            />
        </div>
    )
}

function buildDataSeries(logs) {
    if (Object.keys(logs[0]).filter(key => key !== TIME && key !== COUNT).length < 1) {
        // no other keys than time and count, normal case
        console.log("normal case", [{ data: logs.map(l => l.count), label: 'count' }])
        return [[{ data: logs.map(l => l.count), label: 'count' }], logs.map(l => l.ts)];
    } else if (!Object.keys(logs[0]).find(key => key === TIME)) {
        const otherKey = Object.keys(logs[0]).find(key => key !== COUNT);
        // no time key, abnormal case, just pick first key as the count
        return [[{ data: logs.map(l => l.count), label: 'count' }], logs.map(l => l[otherKey])];
    }

    // const bounusKey = Object.keys(logs[0]).filter(key => key !== TIME && key !== COUNT)[0];
    const bonusKeys = Object.keys(logs[0]).filter(key => key !== TIME && key !== COUNT);

    // join all non-time and count keys as categories
    // const categories = logs.map((log) => log[bounusKey]).filter((value, index, self) => self.indexOf(value) === index);
    let categories = [];
    logs.forEach((log) => {
        let obj = {};
        bonusKeys.forEach(bonusKey => obj[bonusKey] = log[bonusKey]);
        if (categories.find((v) => JSON.stringify(v) === JSON.stringify(obj))) {
            return;
        }
        console.log("obj", obj)
        categories.push(obj);
    })

    categories = categories.map((category) => ({...category, key: Object.values(category).join(",")}));
    
    const times = logs.map((log) => log.ts).filter((value, index, self) => self.indexOf(value) === index);

    const seriesMap = {};
    categories.forEach((category) => seriesMap[category.key] = []);

    const logRowMatches = (log, category) => {
        return Object.keys(category).filter(k => k !== 'key').every(key => log[key] === category[key]);
    }

    console.log("categories", JSON.stringify(categories))
    console.log("times",times.length, JSON.stringify(times))

    times.forEach((time) => {
        categories.forEach((category) => {
            console.log("time", time, "category", category)
            // const log = logs.find((log) => log.ts === time && log[bounusKey] === category);
            const log = logs.find((log) => log.ts === time && logRowMatches(log, category));
            seriesMap[category.key].push(log?.count || null);
            console.log("category", category, "count", seriesMap[category.key].length);
        })
    });


    const series = categories.map((category) => {
        // return { data: seriesMap[category], label: category }
        return { data: seriesMap[category.key], label: category.key }
    })
    console.log("series", series)
    return [series, times];
}